import algoliasearch from 'algoliasearch';
import AllSpaceIndex from '../index/all-space';

/*
 *  Algolia 1 アカウント単にで保持する情報
 */
export default class AllSpaceAccount {
  // staticでもいいけどログアウト時に状態を一発で破棄しやすいようにsingletonにします。

  constructor(applicationId: string, searchOnlyApiKKey: string) {
    this.client = algoliasearch(applicationId, searchOnlyApiKKey);
  }

  private static instance: AllSpaceAccount | undefined;

  private readonly client: algoliasearch.Client;

  public static init = (applicationId: string, apiKey: string) => {
    AllSpaceAccount.instance = new AllSpaceAccount(applicationId, apiKey);
  };

  public static hasInitialized = () => !!AllSpaceAccount.instance;

  public static getInstance = (): AllSpaceAccount => {
    if (!AllSpaceAccount.instance) {
      throw new Error('Algolia not initialized.');
    }
    return AllSpaceAccount.instance;
  };

  public static clear = () => (AllSpaceAccount.instance = undefined);

  /*
   *  Algolia 1 アカウントに紐づく1:nとなる情報
   */
  public readonly indices: any = {
    allSpace: () => AllSpaceIndex.getIndex(this.client)
  };

  public static readonly facets = () => ({
    allSpace: () => AllSpaceIndex.facets()
  });
}
