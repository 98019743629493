import React, { useState } from 'react';
import { TerminateHeader, ContentHeading } from '../molecules/ContentHeader';
import OperationCard from '../molecules/OperationCard';
import TerminateDate from '../molecules/TerminateDate';
import TerminateSubmitModal from '../molecules/TerminateSubmitModal';

import { useHistory } from 'react-router-dom';
import AlgoliaAccount from '../algolia/account/all-space';
import MessageModal from '../molecules/MessageModal';
import moment from 'moment';
import { ifBasePath, defaultProjectId } from '../../config/baseConfig';
import Redux from '../../redux/ReduxConnector';
import req from 'request-promise';
import LocalStorage, { LocalStorageKey } from '../../utils/LocalStorage';
import { CircularProgress } from '@material-ui/core';

const TerminateTemplate: React.FC = () => {
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [terminateDate, setTerminateDate] = useState<string | undefined>(
    moment()
      .add(1, 'months')
      .format('YYYY-MM-DD')
  );
  const [allSpaceId, setAllSpaceId] = useState<string>('');
  const [contractId, setContractId] = useState<string>('');
  const [progress, setProgress] = React.useState(false);

  const history = useHistory();

  const index = React.useMemo(
    () => AlgoliaAccount.getInstance().indices.allSpace(),
    []
  );

  const [algoliaResponse, setAlgoliaResponse] = React.useState<any>(undefined);
  const [open, setOpen] = React.useState<boolean>(false);
  const [isError, setIsError] = React.useState<boolean>(false);

  const setIsModalOpen = React.useCallback(
    (e: any) => {
      setIsError(false);
      setIsOpenConfirmModal(e);
    },
    [setIsError, setIsOpenConfirmModal]
  );

  const getContractStartDate = React.useCallback((res: any) => {
    return res && res.contract && res.contract.occupyScheduledDate
      ? moment(res.contract.occupyScheduledDate).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD');
  }, []);

  React.useEffect(() => {
    setProgress(true);
    const params = new URLSearchParams(history.location.search);
    const paramAllSpaceId = params.get('s') || '';
    const paramContractId = params.get('c') || '';
    setAllSpaceId(paramAllSpaceId);
    setContractId(paramContractId);

    const options: any = {
      method: 'POST',
      uri: `${ifBasePath}project/${defaultProjectId}/application/origin/${paramAllSpaceId}/${paramContractId}`,
      timeout: 30 * 1000, // タイムアウト指定しないと帰ってこない場合がある
      headers: {
        'x-api-key':
          Redux.getStore().user.accessToken ||
          LocalStorage.get(LocalStorageKey.ACCESS_TOKEN)
      },
      json: {
        refresh_token:
          Redux.getStore().user.refreshToken ||
          LocalStorage.get(LocalStorageKey.REFRESH_TOKEN)
      }
    };
    req(options)
      .then(data => {
        setProgress(false);
        // let data;
        // res.forEach(el => (data = { ...data, ...el }));
        index
          .search({
            searchWord: '',
            filters: {
              'sumamoruData.sumamoruLinkable': ['true'],
              id: [paramAllSpaceId]
            },
            excludeFilters: {},
            facets: {},
            numericFilters: [],
            length: 1000,
            offset: 0
          })
          .then(res => {
            const result = res.hits
              .filter(
                hit =>
                  !!hit.sumamoruData &&
                  !!hit.sumamoruData.contracts &&
                  hit.sumamoruData.contracts.length > 0
              )
              .map(hit =>
                hit.sumamoruData.contracts.map(contract => ({
                  base: hit,
                  sumamoruData: hit.sumamoruData,
                  contract: contract
                }))
              )
              .reduce((a, b) => a.concat(b))
              .filter(hit => hit.contract.contractId === paramContractId);
            setAlgoliaResponse({ ...data, ...result[0] });
            index.clearCache();
          })
          .catch(() => {
            setProgress(false);
          });
      })
      .catch(console.log);
  }, [
    history.location.search,
    setAlgoliaResponse,
    setAllSpaceId,
    setContractId,
    index
  ]);

  const detectError = React.useCallback(() => {
    setIsError(true);
  }, [setIsError]);
  const resetError = React.useCallback(() => {
    setIsError(false);
  }, [setIsError]);

  return (
    <>
      <TerminateHeader
        setIsModalOpen={setIsModalOpen}
        all_space_id={allSpaceId}
        contract_id={contractId}
        isError={isError}
        canCancel={!terminateDate}
        errorMessage={'連携に失敗しました。'}
      />
      <TerminateDate
        contractStartDate={getContractStartDate(algoliaResponse)}
        terminateDate={terminateDate}
        setTerminateDate={setTerminateDate}
      />
      <ContentHeading text={'現在の契約内容'} />
      <OperationCard
        algoliaResponse={algoliaResponse}
        detectError={detectError}
        resetError={resetError}
      />
      <TerminateSubmitModal
        all_space_id={algoliaResponse && algoliaResponse.base.id}
        contract_id={algoliaResponse && algoliaResponse.contract.contractId}
        move_plan_date={
          algoliaResponse && algoliaResponse.contract.occupyScheduledDate
        }
        terminate_date={terminateDate}
        isOpen={isOpenConfirmModal}
        setIsOpen={setIsOpenConfirmModal}
        detectError={detectError}
        contractStartDate={getContractStartDate(algoliaResponse)}
        terminateDate={terminateDate}
      />
      <MessageModal
        isOpen={open}
        setIsOpen={setOpen}
        title={'エラー'}
        message={[
          'このデータはすでに解約処理が行われています。',
          'もう一度処理を実行することはできません。'
        ]}
      />
      {progress && (
        <div>
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default TerminateTemplate;
