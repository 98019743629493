import { bmResidence } from '../config/baseConfig';

export interface AuthenticationResult {
  accessToken: string;
  organizationId: string;
  personaId: string;
  refreshToken: string;
  userId: string;
}

export interface ManagementCompanyResponse {
  statusCode: number;
  data?: {
    managementCompanyName?: string;
    managementCompanyAddress?: string;
    managementCompanyPhoneNumber?: string;
    notificationEmail?: string;
  };
}

export function getManagementCompany(
  allSpaceId: string,
  accessToken: string
): Promise<ManagementCompanyResponse> {
  const managementComapnyEndpoint = `${bmResidence}spaces/${allSpaceId}/management_company`;
  const method = 'GET';
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-api-key': accessToken
  };

  return fetch(managementComapnyEndpoint, {
    method,
    headers,
    mode: 'cors'
  })
    .then(res => {
      if (res.status !== 200) {
        return { statusCode: res.status };
      }
      return res
        .json()
        .then(json => {
          console.log('json', json);
          return {
            statusCode: 200,
            data: {
              managementCompanyName: json.managementCompanyName,
              managementCompanyAddress: json.managementCompanyAddress,
              managementCompanyPhoneNumber: json.managementCompanyPhoneNumber,
              notificationEmail: json.notificationEmail
            }
          };
        })
        .catch(e => {
          console.log(e);
          return {
            statusCode: e.status
          };
        });
    })
    .catch(e => {
      console.log(e);
      return {
        statusCode: e.status
      };
    });
}
