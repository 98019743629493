import React, { useCallback } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import { useHistory } from 'react-router';
import { logout } from '../../utils/login-controler';

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      height: 80
    },
    headerInner: {
      margin: '0 auto',
      width: 1216,
      height: 80,
      textAlign: 'left',
      position: 'relative'
    },
    headerTitle: {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 32,
      color: 'var(--color-admin-key)',
      paddingTop: 30
    },
    buttonsContainer: {
      position: 'absolute',
      top: 32,
      right: 0
    },
    button: {
      color: 'var(--color-gray-3)',
      borderRadius: 999,
      marginLeft: 20,
      padding: '8px 12px 8px'
    },
    icon: {
      marginRight: 4
    }
  })
);

const Header: React.FC = () => {
  const styles = useStyles({});
  const history = useHistory();

  const signOut = useCallback(() => {
    logout(history);
  }, [history]);

  return (
    <div className={styles.header}>
      <div className={styles.headerInner}>
        <p className={styles.headerTitle}>スマモル賃貸サービス管理</p>
        <div className={styles.buttonsContainer}>
          <Button className={styles.button} onClick={signOut}>
            <ExitToApp className={styles.icon} />
            ログアウト
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Header;
