import { IconButton as OriginButton } from '@material-ui/core';
import React from 'react';
import { loginChecker } from '../../utils/login-controler';
import { useHistory } from 'react-router';

/**
 * click時にアクセス権限のチェックを行う
 * @param props
 */
export const IconButton: React.FC<any> = (props: any) => {
  const history = useHistory();

  const { onClick, ...otherProps } = props;
  const onClickWithAuthCheck = () => {
    if (loginChecker(history)) {
      return;
    }

    onClick();
  };

  return <OriginButton {...otherProps} onClick={onClickWithAuthCheck} />;
};

export default IconButton;
