import axios, { AxiosInstance, AxiosPromise } from 'axios';
import moment, { Moment } from 'moment';

export interface AuthenticationResult {
  accessToken: string;
  organizationId: string;
  personaId: string;
  refreshToken: string;
  userId: string;
}
export interface EcdsaSignature {
  r: string;
  s: string;
}
export interface Error {
  code: number;
  message: string;
}
export interface InitialAuthenticationRequest {
  email: string;
  passCode?: string;
  password: string;
}
export interface JwkObject {
  crv: 'P-256';
  kid: string;
  kty: 'EC';
  x: string;
  y: string;
}

const meta: any = {
  acceptanceTimeLimitEpoch: 'Datetime',
  endAtEpoch: 'Datetime',
  startAtEpoch: 'Datetime'
};

export interface RefreshAccessTokenRequest {
  refreshToken: string;
}

export interface VerifyAccessTokenResponse {
  expiresAt: number;
  issuedAt: number;
  organizationId: string;
  personaId: string;
}
export interface UpdatePersonaPasswordRequest {
  newPassword: string;
  oldPassword: string;
}

export interface DeactivateRefreshTokensRequest {
  refreshToken: string;
}

export interface AddUserRequest {
  email: string;
}

export interface AddUserResponse {
  signupSessionId: string;
}

export interface ActivateUserRequest {
  authorizationCode: string;
  authorizationCodeSignature?: EcdsaSignature;
  password: string;
  personaPublicKey?: JwkObject;
  public?: boolean;
  signupSessionId: string;
}

export interface ActivateUserResponse extends AuthenticationResult {
  personaPublicKeyId: string;
}

export interface UpdateUserEmailRequest {
  emailUpdateCode?: string;
}

export interface StartUpdateUserEmailRequest {
  email: string;
}

export interface ReissueUserIdResponse {
  newUserId: string;
}

export interface GetVersionResponse {
  gitCommit: string;
  gitTag: string;
}

export interface ResetPasswordRequest {
  password: string;
  resetToken: string;
}

export interface IssuePasswordResetTokenRequest {
  email: string;
  personaId?: string;
}

export type Datetime = number | Moment | Date | string;
const camel = (val: any) =>
  val.replace(/_\w/g, (match: any) => match.charAt(1).toUpperCase());
const snake = (val: any) =>
  val.replace(/[A-Z]/g, (match: any) => '_' + match.toLowerCase());
function toCamel(data: any): any {
  if (!(data instanceof Object)) {
    return data;
  }
  if (Array.isArray(data)) {
    const res: any[] = [];
    for (const d of data) {
      res.push(toCamel(d));
    }
    return res;
  }
  const res: any = {};
  for (const k of Object.keys(data)) {
    res[camel(k)] = toCamel(data[k]);
  }
  return res;
}
function toSnake(data: any): any {
  if (!(data instanceof Object)) {
    return data;
  }
  if (Array.isArray(data)) {
    const res: any[] = [];
    for (const d of data) {
      res.push(toSnake(d));
    }
    return res;
  }
  const res: any = {};
  for (const k of Object.keys(data)) {
    res[snake(k)] = toSnake(data[k]);
  }
  return res;
}

const toDate = (value: number) => new Date(value);
const fromDate = (value: Date) => value.getTime();
const toUTCString = (value: number) => new Date(value).toUTCString();
const fromUTCString = (value: string) => new Date(value).getTime();
const toMoment = (value: number) => moment(value);
const fromMoment = (value: Moment) => value.unix();

export interface Logger {
  log: (...s: any[]) => void;
  warn: (...s: any[]) => void;
  error: (...s: any[]) => void;
}

export enum DatetimeType {
  UNIX = 'UNIX',
  UTC_STRING = 'UTC_STRING',
  DATE = 'DATE',
  MOMENT = 'MOMENT'
}

export interface Option {
  logger?: Logger;
  datetimeType?: DatetimeType;
  updateTokens?: (accessToken: string, refreshToken: string) => void;
}

export default class BitkeyPlatformAPI {
  private static instance: BitkeyPlatformAPI;
  private logger: Logger;
  private refreshToken: string = '';

  public static init(bkpUrl: string, option?: Option): void {
    BitkeyPlatformAPI.instance = new BitkeyPlatformAPI(bkpUrl, option);
  }

  private axiosWithToken: AxiosInstance;
  private axiosWithoutToken: AxiosInstance;
  private datetimeConverter: {
    toRequest: (val: any) => any;
    toResponse: (val: any) => any;
  };
  private readonly bkpUrl: string;

  private updateTokens!: (accessToken: string, refreshToken: string) => void;

  private constructor(bkpUrl: string, option?: Option) {
    if (!option) {
      option = {};
    }
    if (!option.logger) {
      option.logger = {
        log: console.log,
        warn: console.warn,
        error: console.error
      };
    }
    if (!option.datetimeType) {
      option.datetimeType = DatetimeType.DATE;
    }
    this.bkpUrl = bkpUrl;
    this.logger = option.logger!;
    switch (option.datetimeType!) {
      case DatetimeType.DATE:
        this.datetimeConverter = {
          toRequest: fromDate,
          toResponse: toDate
        };
        break;
      case DatetimeType.UNIX:
        this.datetimeConverter = {
          toRequest: val => val,
          toResponse: val => val
        };
        break;
      case DatetimeType.UTC_STRING:
        this.datetimeConverter = {
          toRequest: fromUTCString,
          toResponse: toUTCString
        };
        break;
      case DatetimeType.MOMENT:
        this.datetimeConverter = {
          toRequest: fromMoment,
          toResponse: toMoment
        };
        break;
      default:
        this.datetimeConverter = {
          toRequest: fromDate,
          toResponse: toDate
        };
        break;
    }
    if (option.updateTokens) {
      this.updateTokens = option.updateTokens;
    }
    this.axiosWithToken = axios.create({
      baseURL: bkpUrl,
      headers: { 'Content-Type': 'application/json' }
    });
    this.axiosWithoutToken = axios.create({
      baseURL: bkpUrl,
      headers: { 'Content-Type': 'application/json' }
    });
  }

  public static setTokens = (accessToken: string, refreshToken: string) => {
    BitkeyPlatformAPI.instance.axiosWithToken.defaults.headers[
      'X-Api-Key'
    ] = accessToken;
    BitkeyPlatformAPI.instance.refreshToken = refreshToken;
  };

  private static convertDateTime = (target: any, fieldName: string) => {
    if (!target) return;
    if (!target[fieldName]) return;
    if (meta[fieldName] && meta[fieldName] === 'Datetime') {
      target[
        fieldName
      ] = BitkeyPlatformAPI.instance.datetimeConverter.toRequest(
        target[fieldName]
      );
    } else if (typeof target[fieldName] === 'object') {
      for (const fName of Object.keys(target[fieldName])) {
        BitkeyPlatformAPI.convertDateTime(target[fieldName], fName);
      }
    }
  };

  private static toRequest = (request: any) => {
    //let caller;
    // if (BitkeyPlatformAPI.toRequest.caller) {
    //   caller = BitkeyPlatformAPI.toRequest.caller.name;
    // }
    for (const fieldName of Object.keys(request)) {
      BitkeyPlatformAPI.convertDateTime(request, fieldName);
    }
    request = toSnake(request);
    //BitkeyPlatformAPI.instance.logger.log(`[BKP ${caller} Request]`, request);
    return request;
  };

  private static toResponse = async <T>(
    promise: AxiosPromise<T>
  ): Promise<T> => {
    // let caller;
    // if (BitkeyPlatformAPI.toResponse.caller) {
    //   caller = BitkeyPlatformAPI.toResponse.caller.name;
    // }
    try {
      const response = await promise;
      // BitkeyPlatformAPI.instance.logger.log(`[BKP ${caller} Response]`, response);
      const data = response.data;
      if (!data) {
        // @ts-ignore for void
        return;
      }
      const responseData: any = toCamel(data);
      for (const fieldName of Object.keys(responseData)) {
        if (
          meta[fieldName] &&
          meta[fieldName] === 'Datetime' &&
          responseData[fieldName]
        ) {
          responseData[
            fieldName
          ] = BitkeyPlatformAPI.instance.datetimeConverter.toResponse(
            responseData[fieldName]
          );
        }
      }
      return responseData;
    } catch (e) {
      const err = e.response;
      // BitkeyPlatformAPI.instance.logger.warn(`[BKP ${caller} Error]`, err);
      throw err;
    }
  };

  private static callApi = async <T>(
    axiosWithTokenFunction: () => AxiosPromise<T>
  ): Promise<T> => {
    try {
      return await BitkeyPlatformAPI.toResponse<T>(axiosWithTokenFunction());
    } catch (e) {
      if (e && e.data && e.data.code === 401) {
        if (
          !BitkeyPlatformAPI.instance.axiosWithToken.defaults.headers[
            'X-Api-Key'
          ]
        ) {
          throw e;
        }
        if (!BitkeyPlatformAPI.instance.refreshToken) {
          console.log(
            'If you set refresh token to BitkeyPlatformAPI, token will be refreshed automatically.'
          );
          throw e;
        }
        if (!BitkeyPlatformAPI.instance.updateTokens) {
          console.log(
            'If you set updateTokens function to BitkeyPlatformAPI.init to store new tokens to your storage, you can refresh tokens automatically.'
          );
          throw e;
        }
        let auth;
        try {
          auth = await BitkeyPlatformAPI.refreshAccessToken({
            refreshToken: BitkeyPlatformAPI.instance.refreshToken
          });
        } catch (e) {
          throw e;
        }
        BitkeyPlatformAPI.instance.updateTokens(
          auth.accessToken,
          auth.refreshToken
        );
        BitkeyPlatformAPI.setTokens(auth.accessToken, auth.refreshToken);
        return BitkeyPlatformAPI.toResponse<T>(axiosWithTokenFunction());
      } else {
        throw e;
      }
    }
  };

  /**
   * リフレッシュトークンを利用してアクセストークンを発行し直します。
   */
  public static async refreshAccessToken(
    requestBody: RefreshAccessTokenRequest
  ): Promise<AuthenticationResult> {
    return BitkeyPlatformAPI.toResponse<AuthenticationResult>(
      BitkeyPlatformAPI.instance.axiosWithoutToken.post(
        `/access_tokens/refresh`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * HTTP ヘッダーにセットされたアクセストークンを検証します。
   */
  public static async verifyAccessToken(): Promise<VerifyAccessTokenResponse> {
    return BitkeyPlatformAPI.callApi<VerifyAccessTokenResponse>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.get(`/access_tokens/verify`)
    );
  }

  /**
   * Update password
   */
  public static async updatePersonaPassword(
    personaId: string,
    requestBody: UpdatePersonaPasswordRequest
  ): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/personas/${personaId}/password`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * リフレッシュトークンを無効化します。
   */
  public static async deactivateRefreshTokens(
    requestBody: DeactivateRefreshTokensRequest
  ): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/refresh_tokens/deactivate`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * ユーザー登録のためのセッションを開始します。
   *
   * ユーザー登録のためのセッションID、パスコードが発行されます。
   * セッションIDはAPIレスポンスで返され、パスコードはメールで送付されます。
   */
  public static async addUser(
    requestBody: AddUserRequest
  ): Promise<AddUserResponse> {
    return BitkeyPlatformAPI.toResponse<AddUserResponse>(
      BitkeyPlatformAPI.instance.axiosWithoutToken.post(
        `/users`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * ユーザー登録を完了します。
   *
   * セッションIDとパスコードを検証した上で、ユーザー登録を完了します。
   * 端末認証用の公開鍵を登録する場合は `authorization_code` を `nonce` として署名を作成し、 `authorization_code_signature` に入力してください。
   */
  public static async activateUser(
    requestBody: ActivateUserRequest
  ): Promise<ActivateUserResponse> {
    return BitkeyPlatformAPI.toResponse<ActivateUserResponse>(
      BitkeyPlatformAPI.instance.axiosWithoutToken.post(
        `/users/activate`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * ユーザーを認証してAPIトークンを発行します。
   *
   * 指定された認証情報を元にユーザーを認証して、APIトークンを発行します。
   * APIトークンは、ユーザー登録時に作成されたデフォルトのペルソナを対象に発行されます。
   */
  public static async authenticateUser(
    requestBody: InitialAuthenticationRequest
  ): Promise<AuthenticationResult> {
    return BitkeyPlatformAPI.toResponse<AuthenticationResult>(
      BitkeyPlatformAPI.instance.axiosWithoutToken.post(
        `/users/auth`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * メールアドレスを変更します。
   *
   * 事前にセッションを開始し、パスコードをメールから入手してください。
   */
  public static async updateUserEmail(
    requestBody: UpdateUserEmailRequest
  ): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/users/email`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * メール変更のセッションを開始します。
   *
   * 新しいメールアドレス宛にコードが発行されます。
   */
  public static async startUpdateUserEmail(
    requestBody: StartUpdateUserEmailRequest
  ): Promise<void> {
    return BitkeyPlatformAPI.callApi<void>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.post(
        `/users/email`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }

  /**
   * ユーザーIDを再発行します。
   *
   * ユーザーIDの再発行を行います。
   * 変更後のユーザーIDは指定できません。
   */
  public static async reissueUserId(
    userId: string
  ): Promise<ReissueUserIdResponse> {
    return BitkeyPlatformAPI.callApi<ReissueUserIdResponse>(() =>
      BitkeyPlatformAPI.instance.axiosWithToken.put(
        `/users/${userId}/reissue_id`
      )
    );
  }

  /**
   * バージョンを表示します。
   */
  public static async getVersion(): Promise<GetVersionResponse> {
    return BitkeyPlatformAPI.toResponse<GetVersionResponse>(
      BitkeyPlatformAPI.instance.axiosWithoutToken.get(`/version`)
    );
  }

  /**
   * トークンを利用してパスワードをリセットします。
   */
  public static async resetPassword(
    requestBody: ResetPasswordRequest
  ): Promise<AuthenticationResult> {
    const res = await BitkeyPlatformAPI.toResponse<AuthenticationResult>(
      BitkeyPlatformAPI.instance.axiosWithoutToken.put(
        `/passwords/reset`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
    await BitkeyPlatformAPI.instance.updateTokens(
      res.accessToken,
      res.refreshToken
    );
    BitkeyPlatformAPI.setTokens(res.accessToken, res.refreshToken);
    return res;
  }

  /**
   * パスワードリセットのためのトークンを発行します。
   *
   * システム上に登録されていないアドレスには送信されません。
   * persona_id が未指定の場合はデフォルトのペルソナが対象となります。
   */
  public static async issuePasswordResetToken(
    requestBody: IssuePasswordResetTokenRequest
  ): Promise<void> {
    return BitkeyPlatformAPI.toResponse<void>(
      BitkeyPlatformAPI.instance.axiosWithoutToken.post(
        `/passwords/reset`,
        BitkeyPlatformAPI.toRequest(requestBody)
      )
    );
  }
}
