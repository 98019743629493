import React from 'react';
import { Modal } from '@material-ui/core';
import { Button } from '../atoms/ButtonWithAuth';
import { IconButton } from '../atoms/IconButtonWithAuth';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Close, Warning } from '@material-ui/icons';

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    container: {
      background: 'var(--color-white)',
      borderRadius: 10,
      color: 'var(--color-gray-1)',
      fontSize: 14,
      textAlign: 'left',
      width: 460,
      position: 'relative'
    },
    closeButton: {
      color: 'var(--color-white)',
      position: 'absolute',
      fontSize: 20,
      fontWeight: 900,
      top: 10,
      right: 16
    },
    heading: {
      borderRadius: '10px 10px 0 0',
      color: 'var(--color-white)',
      backgroundColor: 'var(--color-error)',
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '29px',
      letterSpacing: '0.5px',
      padding: '20px'
    },
    root: {
      width: 'calc(100% - 40px)',
      padding: 20
    },
    buttonsContainer: {
      marginTop: 20,
      textAlign: 'right'
    },
    cancelButton: {
      borderRadius: 999,
      padding: '8px 20px',
      color: 'var(--color-gray-1)',
      backgroundColor: 'var(--color-white)',
      marginRight: 16,
      '&:hover': {
        backgroundColor: '#eee'
      }
    },
    submitButton: {
      backgroundColor: 'var(--color-error)',
      borderRadius: 999,
      padding: '8px 20px',
      color: 'var(--color-white)',
      '&:hover': {
        backgroundColor: '#ce4040'
      }
    },
    icon: {
      position: 'relative',
      top: 4,
      marginRight: 9
    }
  })
);

interface Props {
  isOpen: boolean;
  setIsOpen: (e: boolean) => void;
  title: string;
  message: string[];
  execute?: () => void;
}

const MessageModal: React.FC<Props> = props => {
  const { isOpen, setIsOpen, title, message, execute } = props;

  const styles = useStyles({});

  const renderMessage = React.useCallback(() => {
    return (
      <div key={'message'}>
        {message.map(t => (
          <React.Fragment key={t}>
            {t}
            <br />
          </React.Fragment>
        ))}
      </div>
    );
  }, [message]);

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={isOpen}
      className={styles.modal}
      onClose={() => setIsOpen(false)}
    >
      <div className={styles.container}>
        <IconButton
          className={styles.closeButton}
          onClick={() => setIsOpen(false)}
        >
          <Close />
        </IconButton>
        <div className={styles.heading}>
          <span className={styles.icon}>
            <Warning />
          </span>
          {title}
        </div>
        <div className={styles.root}>
          {renderMessage()}
          <div className={styles.buttonsContainer}>
            <Button
              key={'close'}
              className={styles.cancelButton}
              onClick={() => setIsOpen(false)}
            >
              閉じる
            </Button>
            {execute && (
              <Button
                key={'execute'}
                className={styles.submitButton}
                onClick={execute}
              >
                処理を実行
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MessageModal;
