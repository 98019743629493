import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import processSuccess from '../../static/images/processSuccess.png';
import { Button } from '../atoms/ButtonWithAuth';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      height: '100vh',
      alignItems: 'center',
      justifyContent: 'center'
    },
    image: {
      width: 250,
      height: 250
    },
    caption: {
      color: 'var(--color-admin-key)',
      fontSize: 24,
      letterSpacing: '0.05em',
      textAlign: 'center',
      marginTop: 60
    },
    goHome: {
      marginTop: 48
    }
  })
);

const ProcessingItem: React.FC = () => {
  const styles = useStyles({});
  const history = useHistory();

  const handleBackHome = () => {
    history.push('/');
  };

  return (
    <div className={styles.container}>
      <div>
        <img
          className={styles.image}
          src={processSuccess}
          alt={'processing...'}
        />
        <p className={styles.caption}>受付が完了しました</p>
        <Button className={styles.goHome} onClick={handleBackHome}>
          HOMEに戻る
        </Button>
      </div>
    </div>
  );
};

export default ProcessingItem;
