import * as algoliasearch from 'algoliasearch';
import { Index } from 'algoliasearch';
import { AlgoliaConfig } from '../../../config/baseConfig';

// Algoliaの共通メソッド
import Algolia from '../util/algolia-util';
import { AlgoliaIndex } from '../util/algolia-util';
import { AlgoliaQueryCondition } from '../util/algolia-util';

// Indexの要素
enum AlgoliaAttributes {
  objectID = 'objectID',
  sumamoruLinkable = 'sumamoruData.sumamoruLinkable',
  prefecure = 'prefecture'
}

/*
 *  Collectの Algolia 1 Index単位で保持する情報
 */
export default class AlgoliaCollect implements AlgoliaIndex<any> {
  public static readonly facets = () => ({
    // for facet
    sumamoruLinkable: AlgoliaAttributes.sumamoruLinkable,
    prefecture: AlgoliaAttributes.prefecure,

    // for filter
    objectID: AlgoliaAttributes.objectID
  });

  private readonly index: Index;
  private readonly name: string;
  private readonly rawIndexName: string;

  public static getIndex = (client: algoliasearch.Client) =>
    new AlgoliaCollect(client);

  private constructor(client: algoliasearch.Client) {
    this.name = AlgoliaConfig.AllSpace.indexName;
    this.rawIndexName = Algolia.toIndexName(this.name);
    this.index = client.initIndex(this.rawIndexName);
  }

  public clearCache = () => Algolia.clearCache(this.index);

  public getName = () => this.name;

  public search = (condition: AlgoliaQueryCondition) =>
    Algolia.search<any>(this.index, condition);

  public relation = (filters: { [filterAttributeName: string]: string[] }) =>
    Algolia.relation<any>(this.index, filters);

  public facets = () => AlgoliaCollect.facets();

  public browseAll = (condition: any, setIsEnd: any, setResult: any) =>
    Algolia.browseAll<any>(this.index, condition, setIsEnd, setResult);
}
