import React from 'react';
import { CustomTextField as OriginTextField } from './CustomTextField';
import { loginChecker } from '../../utils/login-controler';
import { useHistory } from 'react-router';

type P = any;

/**
 * 項目入力時にアクセス権限のチェックを行う
 *
 * - 1文字目入力したときのみだけcheckする
 * @param props
 */
export const CustomTextField: React.FC<P> = (props: P) => {
  const history = useHistory();

  const { onChange, ...otherProps } = props;

  const onClickWithAuthCheck = e => {
    // console.info(props.value);
    // console.info(props.defaultValue);

    if (
      // 毎回check走ると重くなりそうなので、1文字目を入力したときだけcheckする
      !props.value ||
      props.value.length < 1 ||
      // 初期描画ありで検知しづらいcaseは毎回checkする
      props.defaultValue
    ) {
      if (loginChecker(history)) {
        return;
      }
    }

    onChange(e);
  };

  return (
    <OriginTextField {...otherProps} onChange={e => onClickWithAuthCheck(e)} />
  );
};

export default CustomTextField;
