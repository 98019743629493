import Redux from '../redux/ReduxConnector';
import AuthService from '../utils/BkpAuthService';
import LocalStorage, { LocalStorageKey } from '../utils/LocalStorage';

/**
 * ログアウト時の共通処理
 */
export function logout(history: any, isTimeOut: boolean = false): void {
  if (isTimeOut) {
    history.push('/signin', {
      isTimeOut: true
    });
  } else {
    history.push('/signin');
  }

  Redux.actions.user.clear();

  localStorage.clear();
}

/**
 * ログイン状態をチェックする
 *
 * - APIのtoken有効期限チェック
 * - 最終操作時間から30分経過した場合は追い出す
 *
 * - 戻り値： login権限がない場合trueを返却する
 */
export function loginChecker(history: any): boolean {
  const loginWithAccessToken = async (accessToken, refreshToken) => {
    const responce = await AuthService.loginWithStoredToken(
      accessToken,
      refreshToken
    );
    return responce;
  };

  const user = Redux.getStore().user;

  // 最終操作時間をチェック。30分経過していたら追い出す
  //------------------------------------------
  const expiredSec = 30 * 60; // 許可する無操作時間: 30m
  let operationTimestamp: number =
    user.operationTimestamp ||
    Number(LocalStorage.get(LocalStorageKey.OPERATION_TIMESTAMP)); // 最終操作時間
  const elapsedSec =
    operationTimestamp && Math.floor((Date.now() - operationTimestamp) / 1000); // 最終操作からの経過時間

  if (elapsedSec) {
    if (
      // operationTimestamp値が異常かどうかチェックする（※ 未来日付になっていたら異常）
      elapsedSec < 0 ||
      // 無操作可能時間切れ
      elapsedSec >= expiredSec
    ) {
      logout(history, true);
      return true;
    }

    // checkが問題なかった場合、timestampを更新
    operationTimestamp = Date.now();
    LocalStorage.set(
      LocalStorageKey.OPERATION_TIMESTAMP,
      operationTimestamp.toString()
    );
    Redux.actions.user.setTimestamp(operationTimestamp);
  }

  if (
    !history ||
    !history.location ||
    history.location.pathname !== '/signin'
  ) {
    // signinページ以外でリロードした場合、storeにuser値を復元する
    const accessToken = LocalStorage.get(LocalStorageKey.ACCESS_TOKEN);
    const refreshToken = LocalStorage.get(LocalStorageKey.REFRESH_TOKEN);
    if (!user.authType || user.authType.length === 0) {
      Redux.actions.user.setUser({
        accessToken: accessToken || '',
        authType: LocalStorage.get(LocalStorageKey.AUTH_TYPE) as any,
        refreshToken: refreshToken || '',
        userId: LocalStorage.get(LocalStorageKey.USER_ID) || '',
        operationTimestamp: operationTimestamp || 0
      });
    }
    if (accessToken && refreshToken && operationTimestamp) {
      loginWithAccessToken(accessToken, refreshToken).catch(e =>
        console.log(e)
      );
    } else {
      if (!user || !user.accessToken) {
        logout(history);
        return true;
      }
    }
  }
  return false;
}
