import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { Button } from '../atoms/ButtonWithAuth';
import { ArrowBack, Warning } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { hasCorrectDataAuth } from '../../utils/auth-controler';

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      width: 1216,
      margin: '12px auto 0',
      textAlign: 'left'
    },
    backButton: {
      textDecoration: 'none',
      color: 'var(--color-gray-3)'
    },
    backIcon: {
      marginRight: 4
    },
    headingContainer: {
      position: 'relative'
    },
    headerTitle: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 24,
      color: '#757575',
      width: 1216,
      margin: '8px auto 0',
      textAlign: 'left'
    },
    buttonsContainer: {
      position: 'absolute',
      right: 0,
      bottom: 0
    },
    cancelButton: {
      borderRadius: 999,
      padding: '8px 16px',
      color: 'var(--color-admin-key)',
      backgroundColor: 'var(--color-white)',
      border: '1px solid var(--color-admin-key)',
      marginRight: 8,
      '&:hover': {
        backgroundColor: '#f3f7fd'
      }
    },
    cancelTerminateButton: {
      borderRadius: 999,
      padding: '8px 16px',
      color: 'var(--color-gray-3)',
      backgroundColor: 'var(--color-white)',
      border: '1px solid var(--color-gray-3)',
      marginRight: 8,
      '&:hover': {
        backgroundColor: '#f7f7f7'
      }
    },
    terminateButton: {
      backgroundColor: 'var(--color-admin-key)',
      borderRadius: 999,
      padding: '8px 33px',
      color: 'var(--color-white)',
      '&:hover': {
        backgroundColor: '#2F80ED'
      }
    },
    terminateSubmitButton: {
      backgroundColor: '#EB5757',
      borderRadius: 999,
      padding: '8px 30px',
      color: 'var(--color-white)',
      '&:hover': {
        backgroundColor: '#EB5757'
      }
    }
  })
);

interface OperationHeaderProps {
  setIsModalOpen: (e: boolean) => void;
  all_space_id: string;
  contract_id: string;
  handleTerminate: () => void;
  isError: boolean;
  errorMessage: string;
}

export const OperationHeader: React.FC<OperationHeaderProps> = props => {
  const {
    //all_space_id,
    // contract_id,
    handleTerminate
  } = props;
  const styles = useStyles({});
  const history = useHistory();

  const handleBack = () => {
    history.push('/');
  };

  return (
    <div className={styles.header}>
      <Button className={styles.backButton} onClick={handleBack}>
        <ArrowBack className={styles.backIcon} />
        戻る
      </Button>

      <ErrorMessage open={props.isError} message={props.errorMessage} />

      <div className={styles.headingContainer}>
        <ContentHeading text={'契約内容'} />
        {hasCorrectDataAuth() && (
          <div className={styles.buttonsContainer}>
            <Button
              className={styles.cancelButton}
              onClick={() => props.setIsModalOpen(true)}
            >
              申込キャンセル
            </Button>
            <Button
              className={styles.terminateButton}
              onClick={handleTerminate}
            >
              解約
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

interface TerminateHeaderProps {
  setIsModalOpen: (e: boolean) => void;
  all_space_id: string;
  contract_id: string;
  isError: boolean;
  errorMessage: string;
  canCancel: boolean;
}

export const TerminateHeader: React.FC<TerminateHeaderProps> = props => {
  const styles = useStyles({});
  const history = useHistory();

  const handleBack = () => {
    history.push(`/operation?s=${props.all_space_id}&c=${props.contract_id}`);
  };

  const handleTerminate = () => {
    props.setIsModalOpen(true);
  };

  return (
    <div className={styles.header}>
      <Button className={styles.backButton} onClick={handleBack}>
        <ArrowBack className={styles.backIcon} />
        戻る
      </Button>

      <ErrorMessage open={props.isError} message={props.errorMessage} />

      <div className={styles.headingContainer}>
        <ContentHeading text={'解約受付'} />

        {hasCorrectDataAuth() && (
          <div className={styles.buttonsContainer}>
            <Button
              className={styles.terminateSubmitButton}
              onClick={handleTerminate}
              disabled={props.canCancel}
            >
              解約
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export const ContentHeading = (props: { text: string }) => {
  const styles = useStyles({});

  return <h1 className={styles.headerTitle}>{props.text}</h1>;
};

const useAlertStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 'calc(100% - 32px)',
      height: 21,
      borderRadius: 2,
      backgroundColor: 'var(--color-error)',
      color: 'var(--color-white)',
      padding: 16,
      marginBottom: 16,
      marginTop: 26,
      fontSize: 14
    },
    container: {
      position: 'relative',
      top: -16,
      left: -16
    },
    icon: {
      position: 'absolute',
      top: 16,
      left: 16
    },
    message: {
      position: 'absolute',
      top: 16,
      left: 52
    }
  })
);

interface P {
  open: boolean;
  message: string;
}

const ErrorMessage: React.FC<P> = props => {
  const { open, message } = props;

  const styles = useAlertStyles();

  return (
    <>
      {open && (
        <div className={styles.root}>
          <div className={styles.container}>
            <div className={styles.icon}>
              <Warning />
            </div>
            <div className={styles.message}>{message}</div>
          </div>
        </div>
      )}
    </>
  );
};
