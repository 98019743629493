import { Index, Response } from 'algoliasearch';

export interface AlgoliaObject {
  objectID: string;
}

/*
* filterの型 2パターン
*
* 1. 通常の「Filter」
* 2. 算術演算子を持ったもの
-----------------------------------------------------*/
// TODO 共通化
export type AlgoliaFilters = {
  [filterAttributeName: string]: string[];
};

export enum AlgoliaNumericFilterSign {
  equaul = '=',
  not_equal = '!=',
  greater_than = '>',
  greater_than_or_equal = '>=',
  less_than = '<',
  less_than_or_equal = '<='
}

export type AlgoliaQueryCondition = {
  searchWord: string;
  filters: AlgoliaFilters;
  excludeFilters?: AlgoliaFilters;
  numericFilters?: AlgoliaNumericFilter[];
  facets: AlgoliaFilters;
  length: number;
  offset: number;
};

export type AlgoliaNumericFilter = {
  filterAttributeName: string;
  sign: AlgoliaNumericFilterSign;
  value: number;
};

export interface AlgoliaIndex<T extends AlgoliaObject> {
  getName: () => string;
  search: (condition: {
    searchWord: string;
    filters: AlgoliaFilters;
    excludeFilters?: AlgoliaFilters;
    numericFilters?: AlgoliaNumericFilter[];
    facets: AlgoliaFilters;
    length: number;
    offset: number;
  }) => Promise<Response<T>>;
  browseAll: (condition: any, setIsEnd: any, setResult: any) => void;
  relation: (filters: AlgoliaFilters) => Promise<Response<T> | undefined>;
  facets: () => { [facetName: string]: string };
  clearCache: () => void;
}

export default class Algolia {
  public static toIndexName = (indexName: string) => `${indexName}`;

  /*
  *  検索用のFilterのフォーマットを行う2パターン。
  *
  * 1. filters:　としてAPIにcallする奴のフォーマット
  *　　　⇒　平文の検索条件：　filters
  *　　　⇒　NOT検索用：　excludeFilters
  *　　　⇒　算術演算子付き検索：　numericFilters
  *
  *
  * 2. facetsとしてAPIにcallする奴のフォーマット
  -----------------------------------------------------*/
  private static makeNativeFilters = (
    filters: AlgoliaFilters,
    excludeFilters?: AlgoliaFilters,
    numericFilters?: AlgoliaNumericFilter[]
  ) => {
    let nativeFilter: string = '';

    /* 通常filter
    --------------------------- */
    // 属性ごとにフォーマット
    for (const filterAttributeName of Object.keys(filters)) {
      let attributeFilter: string = '';
      const values = filters[filterAttributeName];
      // 属性ごとに複数値持つ場合は「OR」で接続する
      for (const value of values) {
        attributeFilter += ` OR ${filterAttributeName}:${value}`;
      }
      if (attributeFilter) {
        // 属性ごとに()でくくる（※先頭のORを削除する）
        nativeFilter += ` AND (${attributeFilter.slice(4)})`;
      }
    }

    /* NOT検索 filter
    --------------------------- */
    if (excludeFilters) {
      for (const filterAttributeName of Object.keys(excludeFilters)) {
        let attributeFilter: string = '';
        const values = excludeFilters[filterAttributeName];
        // 属性ごとに複数値持つ場合は「AND」で接続する
        for (const value of values) {
          attributeFilter += ` AND NOT ${filterAttributeName}: "${value}" `;
        }
        if (attributeFilter) {
          // 属性ごとに()でくくる（※先頭のORを削除する）
          nativeFilter += ` AND (${attributeFilter.slice(4)})`;
        }
      }
    }

    /* 算術演算子付き検索 filter
    *
    * signの定義があることをcheckして単純にstringとして出力する
    * 定義がないsignが指定されていた場合はconsole.logを出す
    --------------------------- */
    if (numericFilters) {
      for (const numericFilter of numericFilters) {
        // switch: 算術演算子毎にstring結合する
        switch (numericFilter.sign) {
          case AlgoliaNumericFilterSign.equaul:
            nativeFilter += ` AND ${numericFilter.filterAttributeName} ${AlgoliaNumericFilterSign.equaul} ${numericFilter.value}`;
            break;
          case AlgoliaNumericFilterSign.not_equal:
            nativeFilter += ` AND ${numericFilter.filterAttributeName} ${AlgoliaNumericFilterSign.not_equal} ${numericFilter.value}`;
            break;
          case AlgoliaNumericFilterSign.greater_than:
            nativeFilter += ` AND ${numericFilter.filterAttributeName} ${AlgoliaNumericFilterSign.greater_than} ${numericFilter.value}`;
            break;
          case AlgoliaNumericFilterSign.greater_than_or_equal:
            nativeFilter += ` AND ${numericFilter.filterAttributeName} ${AlgoliaNumericFilterSign.greater_than_or_equal} ${numericFilter.value}`;
            break;
          case AlgoliaNumericFilterSign.less_than:
            nativeFilter += ` AND ${numericFilter.filterAttributeName} ${AlgoliaNumericFilterSign.less_than} ${numericFilter.value}`;
            break;
          case AlgoliaNumericFilterSign.less_than_or_equal:
            nativeFilter += ` AND ${numericFilter.filterAttributeName} ${AlgoliaNumericFilterSign.less_than_or_equal} ${numericFilter.value}`;
            break;
          default:
            console.log('algolia numeric filter sign is invalid.');
        }
      }
    }
    if (nativeFilter) {
      // 何かしら設定がある場合は先頭に余分に「AND」がついているので、それを除去する
      nativeFilter = nativeFilter.slice(5);
    }
    return nativeFilter;
  };

  /* facets検索用の形状にする
  --------------------------------*/
  private static makeNativeFacetFilters = (facets: AlgoliaFilters) => {
    const nativeFacetFilters: string[][] = [];
    for (const facetAttributeName of Object.keys(facets)) {
      const values = facets[facetAttributeName];
      nativeFacetFilters.push(values.map(v => `${facetAttributeName}:${v}`));
    }
    return nativeFacetFilters;
  };

  /* hit配列のフォーマット
   *
   * 空の場合にalgoriaが文字列で返却してくるcaseがあるので、それのフォーマットを行う
   * --------------------------------*/
  public static convertHits = <T extends AlgoliaObject>(
    res: Response<T>
  ): void => {
    if (!res || !res.hits) {
      return;
    }
    for (const hit of res.hits) {
      for (const k of Object.keys(hit)) {
        const value = hit[k];
        if (value === 'null') {
          hit[k] = undefined;
        } else if (value === 'emptystring') {
          hit[k] = '';
        } else if (value === 'emptyarray') {
          hit[k] = [];
        } else if (value === 'emptyobject') {
          hit[k] = {};
        }
      }
    }
  };

  /* algoriaにAPI callする本体
   * resを共通formatする
   *------------------------------------------------------*/
  public static search = async <T extends AlgoliaObject>(
    index: Index,
    condition: {
      searchWord: string;
      filters: AlgoliaFilters;
      excludeFilters?: AlgoliaFilters;
      numericFilters?: AlgoliaNumericFilter[];
      facets: AlgoliaFilters;
      length: number;
      offset: number;
      restrictSearchableAttributes?: string[];
    }
  ): Promise<Response<T>> => {
    const res = await index.search<T>({
      query: condition.searchWord,
      restrictSearchableAttributes: condition.restrictSearchableAttributes,
      filters: Algolia.makeNativeFilters(
        condition.filters,
        condition.excludeFilters,
        condition.numericFilters
      ),
      typoTolerance: false,
      facetFilters: Algolia.makeNativeFacetFilters(condition.facets),
      length: condition.length,
      offset: condition.offset,
      facets: ['*']
    });
    Algolia.convertHits(res);
    return res;
  };

  /**
   * 検索結果から何らかのidでリレーションしたいときに。
   * やってることはsearchと同じ。
   * algoliaの仕様上、filterが空の場合は全部返ってくるが、ここでやりたいことはリレーションなので
   * filterが空の場合は何も返さないようにする
   * @param index
   * @param filters
   */
  public static relation = async <T extends AlgoliaObject>(
    index: Index,
    filters: AlgoliaFilters,
    length?: number
  ): Promise<Response<T>> => {
    if (!filters) {
      filters = {};
    }
    let filterExists = false;
    for (const k of Object.keys(filters)) {
      if (filters[k] && filters[k].length) {
        filterExists = true;
        break;
      }
    }
    const res = await index.search<T>({
      filters: Algolia.makeNativeFilters(filters),
      length: filterExists ? (length ? length : 1000) : 1,
      offset: 0
      // これは使えないか、使うとしてもかなり難しいので使い方間違えないように使えないようにしておく。
      // facets: ['*'],
    });
    Algolia.convertHits(res);
    console.log(res);
    return res;
  };

  public static browseAll = <T extends AlgoliaObject>(
    index: Index,
    condition: {
      searchWord: string;
      filters: AlgoliaFilters;
      excludeFilters?: AlgoliaFilters;
      numericFilters?: AlgoliaNumericFilter[];
      facets?: AlgoliaFilters;
    },
    setIsEnd: any,
    setResult: any
  ): void => {
    if (!setIsEnd || !setResult) {
      return;
    }

    const browser = index.browseAll(condition.searchWord, {
      filters: Algolia.makeNativeFilters(
        condition.filters,
        condition.excludeFilters,
        condition.numericFilters
      ),
      facetFilters:
        condition.facets && Algolia.makeNativeFacetFilters(condition.facets)
    });
    let hits: any[] = [];
    let temp;

    browser.on('result', content => {
      temp = content.hits;
      console.log('[INFO] browseAll result : ' + JSON.stringify(content.hits));
      if (content) {
        hits = hits.concat(temp);
      }
    });

    browser.on('end', () => {
      setResult(hits);
      console.log('Finished!');
      console.log('We got %d hits', hits.length);
      setIsEnd(true);
    });
  };

  public static next = (index: Index, cursor: string) => {
    return index.browseFrom(cursor);
  };

  public static clearCache = (index: Index) => {
    index.clearCache();
  };
}
