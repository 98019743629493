import React from 'react';
import AppContainer from '../components/organisms/AppContainer';
import ProcessingTemplate from '../components/template/ProcessingTemplate';

const ProcessingScreen: React.FC = () => {
  return (
    <AppContainer>
      <ProcessingTemplate />
    </AppContainer>
  );
};

export default ProcessingScreen;
