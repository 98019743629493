import BitkeyPlatformAPI, { ActivateUserResponse } from './bitkey-platform-api';
import { ifBasePath, defaultProjectId } from '../config/baseConfig';
import req from 'request-promise';

export enum AuthServiceError {
  BKP_INVALID_CREDENTIAL = 'BKP_INVALID_CREDENTIAL',
  BKP_EMAIL_INVALID = 'EMAIL_INVALID',
  BKP_FAILED_VERIFY_PASSCODE = 'FAILED_VERIFY_PASSCODE'
}

class AuthServiceImpl {
  public preRegisterUser = async (email: string): Promise<string> => {
    try {
      const res = await BitkeyPlatformAPI.addUser({
        email
      });
      return res.signupSessionId;
    } catch (e) {
      if (e.status === 409 || e.status === 400) {
        throw AuthServiceError.BKP_EMAIL_INVALID;
      }
      throw e;
    }
  };

  public verifyPasscode = async (
    bkpSessionId: string,
    passcode: string,
    password: string
  ): Promise<ActivateUserResponse> => {
    try {
      const bkpAuth = await BitkeyPlatformAPI.activateUser({
        authorizationCode: passcode,
        password,
        public: true,
        signupSessionId: bkpSessionId
      });
      BitkeyPlatformAPI.setTokens(bkpAuth.accessToken, bkpAuth.refreshToken);

      return bkpAuth;
    } catch (e) {
      if (e.status === 401) {
        console.log('コードが異なります');
        throw AuthServiceError.BKP_FAILED_VERIFY_PASSCODE;
      }
      throw e;
    }
  };

  /**
   * こっち使う場合は色々な観点からfirebaseとbkpの両方に対してこのタイミングでauthを通す。
   * @param email
   * @param password
   */
  public loginWithEmail = async (
    email: string,
    password: string
  ): Promise<{
    accessToken: string;
    refreshToken: string;
    userId: string;
    authType: string;
  }> => {
    // SlackTrace.trigger(email);
    console.log('try bkp login');
    try {
      /**
       * Login to BKP
       */
      const bkpAuth = await BitkeyPlatformAPI.authenticateUser({
        email,
        password
      }).catch(e => {
        console.error(e);
        throw AuthServiceError.BKP_INVALID_CREDENTIAL;
      });

      BitkeyPlatformAPI.setTokens(bkpAuth.accessToken, bkpAuth.refreshToken);

      const options: any = {
        method: 'POST',
        uri: `${ifBasePath}application/user/auth/`,
        timeout: 30 * 1000, // タイムアウト指定しないと帰ってこない場合がある
        headers: {
          'x-api-key': bkpAuth.accessToken,
          'Content-Type': 'application/json'
        },
        json: {
          email: email,
          refresh_token: bkpAuth.refreshToken,
          projectId: defaultProjectId
        }
      };
      const userAuth = await req(options)
        .then(res => {
          if (res.user_auth === 'INVALID') {
            throw AuthServiceError.BKP_INVALID_CREDENTIAL;
          }
          return res.user_auth;
        })
        .catch(error => {
          console.error(error);
          throw AuthServiceError.BKP_INVALID_CREDENTIAL;
        });

      return {
        accessToken: bkpAuth.accessToken,
        refreshToken: bkpAuth.refreshToken,
        userId: bkpAuth.userId,
        authType: userAuth
      };
    } catch (e) {
      throw e;
    }
  };

  public logout = async () => {
    // localからトークンを消す
  };

  public changeEmail = async (newEmail: string): Promise<boolean> => {
    return BitkeyPlatformAPI.startUpdateUserEmail({
      email: newEmail
    })
      .then(async () => {
        return true;
      })
      .catch(e => {
        console.log(e);
        return false;
      });
  };

  public changePassword = async (oldPassword: string, newPassword: string) => {
    const personaId =
      'どっかからとってくるpersonaId おそらくローカルストレージ';
    await BitkeyPlatformAPI.updatePersonaPassword(personaId, {
      newPassword,
      oldPassword
    }).catch(e => {
      throw new Error('パスワードの変更に失敗');
    });

    return true;
  };

  public async issuePasswordReset(
    email: string,
    personaId?: string
  ): Promise<void> {
    await BitkeyPlatformAPI.issuePasswordResetToken({
      email,
      personaId
    });
  }

  public async passwordReset(
    resetToken: string,
    email: string,
    password: string
  ): Promise<void> {
    await BitkeyPlatformAPI.resetPassword({
      password,
      resetToken
    });
  }

  public async loginWithStoredToken(
    accessToken: string,
    refreshToken: string
  ): Promise<boolean> {
    BitkeyPlatformAPI.setTokens(accessToken, refreshToken);
    try {
      await BitkeyPlatformAPI.verifyAccessToken();
    } catch (e) {
      try {
        const refresh = await BitkeyPlatformAPI.refreshAccessToken({
          refreshToken: refreshToken
        }).catch(e => {
          throw e;
        });
        BitkeyPlatformAPI.setTokens(refresh.accessToken, refresh.refreshToken);
        await BitkeyPlatformAPI.verifyAccessToken().catch(e => {
          throw e;
        });
        return new Promise(resolve => resolve(true));
      } catch (e) {
        throw e;
      }
    }
    return new Promise(resolve => resolve(true));
  }
}

const AuthService = new AuthServiceImpl();

export default AuthService;
