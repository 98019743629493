import { withStyles } from '@material-ui/styles';
import { TextField } from '@material-ui/core';

export const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#E0E0E0'
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#E0E0E0'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--color-admin-key)'
      },
      '&:hover.Mui-focused fieldset': {
        borderColor: 'var(--color-admin-key)'
      },

      '&.Mui-error fieldset': {
        background:
          'background: linear-gradient(0deg, rgba(233, 30, 99, 0.07), rgba(233, 30, 99, 0.07)), #FFFFFF',
        borderColor: 'var(--color-error)'
      },
      '&.Mui-error input': {
        background: '#FDEFF4'
      },
      '& input': {
        borderRadius: '4px'
      },
      '& .MuiOutlinedInput-input': {
        padding: '12px',
        fontSize: 14,
        color: 'var(--color-text)'
      },
      '& .MuiOutlinedInput-input:placeholder': {
        fontSize: 14
      }
    }
  }
})(TextField);
