import React from 'react';
import AppContainer from '../components/organisms/AppContainer';
import OperationTemplate from '../components/template/OperationTemplate';

const OperationScreen: React.FC = () => {
  return (
    <AppContainer>
      <OperationTemplate />
    </AppContainer>
  );
};

export default OperationScreen;
