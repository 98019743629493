import { combineReducers, createStore } from 'redux';
import { userReducer } from './user';
import { UserAuth } from './user-auth';

export interface Store {
  user: UserAuth;
}

export default createStore(
  combineReducers({
    user: userReducer
  })
  // TODO: 開発時のみ利用
  // (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
  //   (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);
