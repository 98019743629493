import React from 'react';
import RealEstateSearchForm from '../molecules/RealEstateSearchForm';
import SearchResultList from '../organisms/SearchResultList';

import AlgoliaAccount from '../algolia/account/all-space';
import TablePagination from '../molecules/TablePagination';

const IndexTemplate: React.FC = () => {
  const index = React.useMemo(
    () => AlgoliaAccount.getInstance().indices.allSpace(),
    []
  );

  const [algoliaResponse, setAlgoliaResponse] = React.useState<any>(undefined);
  const [allCount, setAllCount] = React.useState<number>(0);

  // 画面表示のハンドリング上必要な要素
  const [page, setPage] = React.useState<number>(0); // 今表示中のページ数
  const rowsPerPage = 20; // 1ページあたりの表示件数(行数): 画面表示制御専用の値（※ APIでは利用しない）

  // APIの都合上、全データに対して表示範囲を制御する形式
  const displayStart = page * rowsPerPage;
  const displayResponse = algoliaResponse
    ? algoliaResponse.slice(displayStart, displayStart + rowsPerPage)
    : [];

  return (
    <>
      <RealEstateSearchForm
        index={index}
        rowsPerPage={rowsPerPage}
        page={page}
        algoliaResponse={algoliaResponse}
        setAlgoliaResponse={setAlgoliaResponse}
        setAllCount={setAllCount}
        onChangePage={setPage}
      />
      <TablePagination
        onChangePage={setPage}
        rowsPerPage={rowsPerPage}
        page={page}
        count={allCount}
      />
      <SearchResultList algoliaResponse={displayResponse} />
    </>
  );
};

export default IndexTemplate;
