import React from 'react';
import { KeyboardDatePicker as OriginDatePicker } from '@material-ui/pickers';
import { loginChecker } from '../../utils/login-controler';
import { useHistory } from 'react-router';

/**
 * click時にアクセス権限のチェックを行う
 * @param props
 */
export const KeyboardDatePicker: React.FC<any> = (props: any) => {
  const history = useHistory();

  const { onChange, ...otherProps } = props;
  const onChangeWithAuthCheck = (e: any) => {
    if (loginChecker(history)) {
      return;
    }

    onChange(e);
  };

  return (
    <OriginDatePicker
      {...otherProps}
      onChange={e => onChangeWithAuthCheck(e)}
    />
  );
};

export default KeyboardDatePicker;
