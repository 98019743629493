import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Info } from '@material-ui/icons';
import moment from 'moment';
import jaLocale from 'date-fns/locale/ja';
import KeyboardDatePicker from '../atoms/DatePickerWithAuth';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      background: 'var(--color-white)',
      borderRadius: 16,
      padding: '32px ',
      boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.2)',
      width: 1152,
      margin: '12px auto 32px',
      textAlign: 'left'
    },

    link: {
      textDecoration: 'none',
      display: 'block',
      padding: 'inherit'
    },
    termnateHeading: {
      fontSize: 20,
      fontWeight: 500,
      letterSpacing: '0.5px',
      color: 'var(--color-gray-2)'
    },
    terminateLabel: {
      marginTop: 10,
      fontSize: 12,
      color: 'var(--color-gray-3)',
      fontWeight: 500
    },
    infoContainer: {
      backgroundColor: '#D6E7FF',
      borderRadius: 2,
      marginTop: 16,
      padding: 20,
      display: 'flex'
    },
    infoIcon: {
      color: 'var(--color-admin-key)'
    },
    infoText: {
      marginLeft: 16,
      fontSize: 14,
      color: 'var(--color-admin-key)',
      lineHeight: '20px'
    }
  })
);

interface Props {
  contractStartDate: string;
  terminateDate: string | undefined;
  setTerminateDate: (e: string | undefined) => void;
}

const TerminateDate: React.FC<Props> = props => {
  const styles = useStyles({});
  const [is2YearPassed, setIs2YearPassed] = React.useState<boolean>(false);
  const [cancelPlanDate, setCancelPlanDate] = React.useState('');
  const [dateErrorMessage, setDateErrorMessage] = React.useState('');
  const registerdFormat = 'YYYY-MM-DD';

  const handleDateChange = (date: Date | null) => {
    const value = date !== null ? date : undefined;
    if (
      !date ||
      date.toString() === 'Invalid Date' ||
      date.toString() === 'Invalid date'
    ) {
      setDateErrorMessage('有効な日付を入力してください。');
      props.setTerminateDate(undefined);
      return;
    }
    console.log('tatetaiogujoiaewuio uteawopi', date);
    setDateErrorMessage('');
    const isoDate = value && new Date(value).toISOString();
    props.setTerminateDate(
      value && value.toString() ? moment(isoDate).format(registerdFormat) : ''
    );
    calcPeriod();
  };

  const calcPeriod = React.useCallback(() => {
    setIs2YearPassed(
      moment(props.terminateDate, registerdFormat).diff(
        moment(props.contractStartDate),
        'years'
      ) >= 2
    );
    setCancelPlanDate(
      moment(props.contractStartDate, registerdFormat)
        .add(2, 'year')
        .subtract(1, 'day')
        .format('YYYY年M月D日')
    );
  }, [props]);

  React.useEffect(() => {
    calcPeriod();
  }, [calcPeriod]);

  return (
    <div className={styles.container}>
      <h2 className={styles.termnateHeading}>解約日を入力してください</h2>
      <p className={styles.terminateLabel}>解約日</p>

      <MuiPickersUtilsProvider locale={jaLocale} utils={DateFnsUtils}>
        <KeyboardDatePicker
          margin="normal"
          color="primary"
          id="date-picker-dialog"
          label=""
          format="yyyy-MM-dd"
          invalidDateMessage={'有効な日付を入力してください。'}
          value={props.terminateDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          error={!!dateErrorMessage}
          helperText={dateErrorMessage}
          cancelLabel="キャンセル"
          disablePast
          disableToolbar
        />
      </MuiPickersUtilsProvider>
      <div className={styles.infoContainer}>
        <Info className={styles.infoIcon} />
        {!is2YearPassed && (
          <p className={styles.infoText}>
            入居から2年未満の契約です。
            <br />
            電気契約を解約した場合でも、
            <br />
            ・bitlock
            <br />
            ・ベネフィット・ワン
            <br />
            ・大阪ガスセキュリティサービス
            <br />
            のサービスは、{cancelPlanDate}まで継続利用可能です。
          </p>
        )}
        {is2YearPassed && (
          <p className={styles.infoText}>
            スマモル賃貸プラン申込日から2年以上経過しているため、解約に伴い現在利用中のサービスを全て停止します。
          </p>
        )}
      </div>
    </div>
  );
};

export default TerminateDate;
