import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Header from '../molecules/Header';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      backdropFilter: 'blur(30px)',
      minHeight: 'calc(100vh - 32px)',
      paddingBottom: 32
    },
    header: {
      background: 'rgba(50, 255, 255, 0.8)',
      height: 80
    }
  })
);

const AppContainer = (props: { children: any }) => {
  const styles = useStyles({});

  return (
    <div className={styles.container}>
      <Header />
      {props.children}
    </div>
  );
};

export default AppContainer;
