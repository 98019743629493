import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import SearchResultItem from '../molecules/SearchResultItem';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      background: 'var(--color-white)',
      // borderRadius: 16,
      boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.2)',
      width: 1216,
      margin: '0px auto 0'
    },
    table: {
      borderCollapse: 'collapse'
    },
    thead: {
      height: 48
    },
    tr: {
      border: '1px solid #E0E0E0',
      borderWidth: '0px 0px 1px',
      borderCollapse: 'collapse',
      textAlign: 'left',
      '& th': {
        height: 48,
        color: 'var(--color-gray-3)',
        fontSize: 12,
        paddingLeft: '20px',
        border: '1px solid #E0E0E0',
        borderCollapse: 'collapse',
        borderWidth: '0px 0px 1px'
      }
    },
    address: {
      width: 700
    },
    name: {
      width: 126
    },
    kana: {
      width: 194
    },
    phone: {
      width: 220
    }
  })
);

type P = {
  algoliaResponse: Array<any>;
};

const SearchResultList: React.FC<P> = (props: P) => {
  const { algoliaResponse } = props;

  const styles = useStyles({});

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead className={styles.thead}>
          <tr className={styles.tr}>
            <th className={styles.address}>住所</th>
            <th className={styles.name}>契約者名義</th>
            <th className={styles.kana}>契約者名義カナ</th>
            <th className={styles.phone}>電話番号</th>
          </tr>
        </thead>
        <tbody>
          {algoliaResponse && algoliaResponse.length > 0 ? (
            algoliaResponse.map(data => {
              return (
                <SearchResultItem
                  key={data.base.id + data.contract.contractId}
                  data={data}
                />
              );
            })
          ) : (
            <SearchResultItem key={'none'} data={null} />
          )}
        </tbody>
      </table>
    </div>
  );
};

export default SearchResultList;
