import React, { useState } from 'react';
import OperationCard from '../molecules/OperationCard';
import OperationCancelModal from '../molecules/OperationCancelModal';
import { OperationHeader } from '../molecules/ContentHeader';

import { useHistory } from 'react-router-dom';
import AlgoliaAccount from '../algolia/account/all-space';
import MessageModal from '../molecules/MessageModal';
import moment from 'moment';
import { ifBasePath, defaultProjectId } from '../../config/baseConfig';
import Redux from '../../redux/ReduxConnector';
import req from 'request-promise';
import LocalStorage, { LocalStorageKey } from '../../utils/LocalStorage';
import { CircularProgress } from '@material-ui/core';
import { BasicItemKeys } from '../../enums/item/basic-item-keys';

const OperationTemplate: React.FC = () => {
  const history = useHistory();

  const index = React.useMemo(
    () => AlgoliaAccount.getInstance().indices.allSpace(),
    []
  );

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const [algoliaResponse, setAlgoliaResponse] = React.useState<any>(undefined);
  const [progress, setProgress] = React.useState(false);

  // バリデーションエラー用
  const [open, setOpen] = React.useState<boolean>(false);

  // 通信エラー用
  const [isError, setIsError] = React.useState<boolean>(false);
  const detectError = React.useCallback(() => setIsError(true), [setIsError]);
  const resetError = React.useCallback(() => setIsError(false), [setIsError]);

  const handleTerminate = React.useCallback(() => {
    if (
      algoliaResponse &&
      false
      // !(
      //   algoliaResponse.contract.sumamoruStatus === 'all_in_use' &&
      //   algoliaResponse.contract.applicationStatus !== 'application_request'
      // )
    ) {
      setOpen(true);
    } else {
      // TODO stateに値を保存する
      const params = new URLSearchParams(history.location.search);
      const allSpaceId = params.get('s') || '';
      const contractId = params.get('c') || '';
      history.push(`/terminate?s=${allSpaceId}&c=${contractId}`);
    }
  }, [algoliaResponse, history]);

  const cancelContract = React.useCallback(
    (e: any) => {
      setIsError(false);
      setIsCancelModalOpen(true);
    },
    [setIsCancelModalOpen, setIsError]
  );

  React.useEffect(() => {
    setProgress(true);
    const params = new URLSearchParams(history.location.search);
    const allSpaceId = params.get('s') || '';
    const contractId = params.get('c') || '';

    const options: any = {
      method: 'POST',
      uri: `${ifBasePath}project/${defaultProjectId}/application/origin/${allSpaceId}/${contractId}`,
      timeout: 30 * 1000, // タイムアウト指定しないと帰ってこない場合がある
      headers: {
        'x-api-key':
          Redux.getStore().user.accessToken ||
          LocalStorage.get(LocalStorageKey.ACCESS_TOKEN)
      },
      json: {
        refresh_token:
          Redux.getStore().user.refreshToken ||
          LocalStorage.get(LocalStorageKey.REFRESH_TOKEN)
      }
    };
    req(options)
      .then(data => {
        console.log('ttttttewtqaetaweteawt', JSON.stringify(data));
        // let data;
        // res.forEach(el => (data = { ...data, ...el }));
        index
          .search({
            searchWord: '',
            filters: {
              'sumamoruData.sumamoruLinkable': ['true'],
              id: [allSpaceId]
            },
            excludeFilters: {},
            facets: {},
            numericFilters: [],
            length: 1000,
            offset: 0
          })
          .then(res => {
            setProgress(false);
            const result = res.hits
              .filter(
                hit =>
                  !!hit.sumamoruData &&
                  !!hit.sumamoruData.contracts &&
                  hit.sumamoruData.contracts.length > 0
              )
              .map(hit =>
                hit.sumamoruData.contracts.map(contract => ({
                  base: hit,
                  sumamoruData: hit.sumamoruData,
                  contract: contract
                }))
              )
              .reduce((a, b) => a.concat(b))
              .filter(hit => hit.contract.contractId === contractId);
            setAlgoliaResponse({ ...data, ...result[0] });
            index.clearCache();
          })
          .catch(() => {
            setProgress(false);
          });
      })
      .catch(console.log);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search, setAlgoliaResponse]);

  return (
    <>
      <OperationHeader
        setIsModalOpen={cancelContract}
        all_space_id={
          algoliaResponse && algoliaResponse[BasicItemKeys.AllSpaceId]
        }
        contract_id={
          algoliaResponse && algoliaResponse[BasicItemKeys.ContractId]
        }
        handleTerminate={handleTerminate}
        isError={isError}
        errorMessage={'連携に失敗しました。'}
      />
      <OperationCard
        algoliaResponse={algoliaResponse}
        detectError={detectError}
        resetError={resetError}
      />
      <OperationCancelModal
        isOpen={isCancelModalOpen}
        isOverContractDate={
          algoliaResponse &&
          !moment().isBefore(
            algoliaResponse[BasicItemKeys.HasElectricContract]
              ? algoliaResponse[BasicItemKeys.ElectricityStartDate]
              : algoliaResponse[BasicItemKeys.OccupyScheduledDate]
          )
        }
        setIsOpen={e => setIsCancelModalOpen(e)}
        all_space_id={
          algoliaResponse && algoliaResponse[BasicItemKeys.AllSpaceId]
        }
        contract_id={
          algoliaResponse && algoliaResponse[BasicItemKeys.ContractId]
        }
        has_electric_contract={
          algoliaResponse && algoliaResponse[BasicItemKeys.HasElectricContract]
        }
        detectError={detectError}
      />
      {/* バリデーションエラー */}
      <MessageModal
        key={'errorMessage'}
        isOpen={open}
        setIsOpen={setOpen}
        title={'エラー'}
        message={[
          'このデータはすでに解約処理が行われています。',
          'もう一度処理を実行することはできません。'
        ]}
      />
      {progress && (
        <div>
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default OperationTemplate;
