import ReduxUtil from './ReduxUtil';
import { UserAuth } from './user-auth';

const getInitialState = (): UserAuth => ({
  accessToken: '',
  refreshToken: '',
  userId: '',
  operationTimestamp: 0
});

const TYPE_PREFIX = 'USER';
const type = type => TYPE_PREFIX + type;

export const UserActions = {
  clear: () => ({ type: type('clear') }),
  setUser: (user: UserAuth) => ({
    type: type('setUser'),
    user: convertAuthToState(user)
  }),
  setTimestamp: (operationTimestamp: number) => ({
    type: type('setOperationTimestamp'),
    timestamp: operationTimestamp
  })
};

const UserReducerDefinitions = {
  clear: () => getInitialState(),
  setUser: (state, action) => action.user,
  setOperationTimestamp: (state, action) => {
    return { ...state, operationTimestamp: action.timestamp };
  }
};

const userReducer = ReduxUtil.createReducer(
  getInitialState(),
  UserReducerDefinitions,
  TYPE_PREFIX
);

export const convertAuthToState = (userAuth: UserAuth): UserAuth => {
  if (!userAuth || !userAuth.accessToken) {
    return getInitialState();
  }

  return userAuth;
};

export { userReducer };
