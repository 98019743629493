import * as React from 'react';
import { connect } from 'react-redux';
import { UserActions } from './user';
import { UserAuth } from './user-auth';
import ReduxUtil from './ReduxUtil';

interface ConnectedStore {
  user: UserAuth;
}

interface Actions {
  user: typeof UserActions;
}

interface P {
  store?: ConnectedStore;
  dispatch?: any;
}

const mapStateToProps = (state: ConnectedStore): { store: ConnectedStore } => ({
  store: {
    user: state.user
  }
});

const mapDispatchToProps = dispatch => ({
  dispatch: {
    user: ReduxUtil.actionToDispatch(dispatch, UserActions)
  }
});

/**
 * reduxを利用するときはこれを任意の場所から呼ぶ
 */
export default class Redux {
  public static actions: Actions;
  public static getStore: () => ConnectedStore;
}

/**
 * App直下にマウントしておくもの
 * このクラスのpropsにreduxを繋げ、上記Reduxクラスを経由してstaticに呼び出す
 */
class ReduxConnector extends React.Component<P> {
  constructor(props: P) {
    super(props);
    Redux.actions = this.props.dispatch!;
    Redux.getStore = () => this.props.store!;
  }

  render():
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | string
    | number
    | {}
    | React.ReactNodeArray
    | React.ReactPortal
    | boolean
    | null
    | undefined {
    return null;
  }
}

export const ReduxConnectorComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReduxConnector);
