export enum LocalStorageKey {
  ACCESS_TOKEN = 'ACCESS_TOKEN',
  ORGANIZATION_ID = 'ORGANIZATION_ID',
  PERSONA_ID = 'PERSONA_ID',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  USER_ID = 'USER_ID',
  AUTH_TYPE = 'AUTH_TYPE',
  OPERATION_TIMESTAMP = 'OPERATION_TIMESTAMP',

  POST_CODE = 'POST_CODE',
  PREFECTURE = 'PREFECTURE',
  CITY = 'CITY',
  ADDRESS = 'ADDRESS',
  BUILDING_NAME = 'BUILDING_NAME',
  FIRST_NAME_KANA = 'FIRST_NAME_KANA',
  LAST_NAME_KANA = 'LAST_NAME_KANA',
  TELL = 'TELL'
}

export default class LocalStorage {
  public static get = (key: LocalStorageKey) => localStorage.getItem(key);
  public static set = (key: LocalStorageKey, value: string) =>
    localStorage.setItem(key, value);
  public static remove = (key: LocalStorageKey) => localStorage.removeItem(key);
}
