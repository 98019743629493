import React from 'react';
import { loginChecker } from '../../utils/login-controler';
import { useHistory } from 'react-router';

interface P {
  children?: any;
}

/**
 * 権限があるかどうかをcheckしてから描画する
 *
 * - 権限がない場合は描画しない
 */
export const AuthenticationController: React.FC = (props: P) => {
  const history = useHistory();
  if (loginChecker(history)) return null;

  return props.children;
};
