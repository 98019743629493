import React from 'react';
import { MenuItem, FormGroup } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Select from '../atoms/SelectWithAuth';

import moment from 'moment';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: 16,
      width: 60,
      padding: 12,
      fontSize: 14,
      color: 'var(--color-text)',
      borderColor: 'var(--color-gary-3)'
    },
    container: {
      marginTop: 7,
      width: 360,
      display: 'flex',
      justifyContent: 'space-between'
    },
    isChanged: {
      backgroundColor: '#DEF2FF'
    },
    isError: {
      backgroundColor: '#FDEFF4'
    },
    errorMessage: {
      color: '#F44336',
      fontSize: 12,
      margin: '4px 14px 0'
    }
  })
);

interface P {
  placeholder: string;
  items: { label: string; value: string }[];
  value: string;
  setValue: (value: string) => void;
  isChanged: boolean;
  isError?: boolean;
}

const DateSelect: React.FC<P> = props => {
  const selectStyles = useStyles();
  const onChange = React.useCallback(
    (e: any) => {
      props.setValue(e.target.value);
    },
    [props]
  );
  return (
    <Select
      key={props.placeholder}
      variant="outlined"
      classes={{ root: selectStyles.root }}
      className={
        props.isError
          ? selectStyles.isError
          : props.isChanged
          ? selectStyles.isChanged
          : ''
      }
      value={props.value}
      onChange={onChange}
      error={props.isError}
      displayEmpty
    >
      <MenuItem key={'label'} value="" disabled>
        {props.placeholder}
      </MenuItem>
      {props.items.map(item => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </Select>
  );
};

interface T {
  initialValue: string;
  value: string;
  setValue: (e: any) => void;
}

export const CustomDateSelect: React.FC<T> = props => {
  const selectStyles = useStyles();
  console.log(
    'propspropspropspropspropspropspropsprops',
    JSON.stringify(props.value)
  );
  const [year, setYear] = React.useState(
    props.value ? props.value.slice(0, 4) : ''
  );
  const [month, setMonth] = React.useState(
    props.value ? props.value.slice(4, 6) : ''
  );
  const [day, setDay] = React.useState(
    props.value ? props.value.slice(6, 8) : ''
  );
  const [dayErrorMessage, setDayErrorMessage] = React.useState('');
  const [isError, setIsError] = React.useState(false);

  const birthRegex = /^(?!([02468][1235679]|[13579][01345789])000229)(([0-9]{4}(01|03|05|07|08|10|12)(0[1-9]|[12][0-9]|3[01]))|([0-9]{4}(04|06|09|11)(0[1-9]|[12][0-9]|30))|([0-9]{4}02(0[1-9]|1[0-9]|2[0-8]))|([0-9]{2}([02468][048]|[13579][26])0229))$/;

  const handleValidation = React.useCallback(
    (value: string) => {
      if (value === '') {
        setDayErrorMessage('入力してください');
        setIsError(true);
      } else if (!value.match(birthRegex)) {
        setDayErrorMessage('有効な日付を入力してください。');
        setIsError(true);
      } else {
        setDayErrorMessage('');
        setIsError(false);
      }
    },
    [setDayErrorMessage, birthRegex]
  );

  // プルダウンメニュー生成
  const years = React.useMemo(() => {
    const options: { label: string; value: string }[] = [];
    const thisYear = moment().year();
    const iteration = thisYear - 1900;
    for (let y = 0; y < iteration; y++) {
      const value = thisYear - (iteration - 1 - y);
      options.push({ label: `${value}`, value: `${value}` });
    }
    return options;
  }, []);
  const months = React.useMemo(() => {
    const options: { label: string; value: string }[] = [];
    for (let m = 1; m < 13; m++) {
      options.push({ label: `${m}`, value: `${('0' + m).slice(-2)}` });
    }
    return options;
  }, []);
  const days = React.useMemo(() => {
    const options: { label: string; value: string }[] = [];
    for (let d = 1; d < 32; d++) {
      options.push({ label: `${d}`, value: `${('0' + d).slice(-2)}` });
    }
    return options;
  }, []);

  const isChanged = React.useCallback((init, current, type) => {
    const range =
      type === 'year'
        ? { from: 0, to: 4 }
        : type === 'month'
        ? { from: 4, to: 6 }
        : type === 'day'
        ? { from: 6, to: 8 }
        : undefined;
    if (!range) {
      return false;
    } else if (init === '' || !init) {
      return init !== current;
    } else if (props.initialValue && init.length < 8) {
      return !current;
    } else {
      return init.slice(range.from, range.to) !== current;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    props.setValue({ target: { value: `${year}/${month}/${day}` } });
    handleValidation(year + month + day);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, month, day]);

  return (
    <FormGroup key={'date'} row={true} className={selectStyles.container}>
      <DateSelect
        key={'year'}
        placeholder={'年'}
        items={years}
        value={year}
        setValue={setYear}
        isChanged={isChanged(props.initialValue, year, 'year')}
      />
      <DateSelect
        key={'month'}
        placeholder={'月'}
        items={months}
        value={month}
        setValue={setMonth}
        isChanged={isChanged(props.initialValue, month, 'month')}
      />
      <DateSelect
        key={'day'}
        placeholder={'日'}
        items={days}
        value={day}
        setValue={setDay}
        isChanged={isChanged(props.initialValue, day, 'day')}
        isError={isError}
      />
      <div className={selectStyles.errorMessage}>{dayErrorMessage}</div>
    </FormGroup>
  );
};
