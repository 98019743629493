import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() =>
  createStyles({
    tr: {
      width: 1216,
      height: 60,
      textAlign: 'left',
      border: '1px solid #E0E0E0',
      borderWidth: '0px 0px 1px',
      borderCollapse: 'collapse',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#f7f7f7'
      },
      '& td': {
        height: 48,
        color: 'var(--color-text)',
        fontSize: 14,
        paddingLeft: '20px',
        border: '1px solid #E0E0E0',
        borderCollapse: 'collapse',
        borderWidth: '1px 0px 0px'
      }
    },
    link: {
      textDecoration: 'none',
      display: 'block',
      padding: 'inherit'
    }
  })
);

type P = {
  data: any;
};

const SearchResultItem: React.FC<P> = (props: P) => {
  const { data } = props;

  const styles = useStyles({});
  const history = useHistory();

  if (!data) {
    return (
      <tr className={styles.tr}>
        <td>該当するデータはありません。</td>
      </tr>
    );
  }

  const handleClick = () => {
    history.push(`/operation?s=${data.base.id}&c=${data.contract.contractId}`);
  };

  return (
    <tr className={styles.tr} onClick={handleClick} key={data.base.id}>
      <td key={'postcode'}>{`〒${data.base.postcode || ''} ${data.base
        .address || ''}${data.base.buildingName || ''}${data.base.name ||
        ''}`}</td>
      <td key={'customerName'}>{data.contract.customerName}</td>
      <td key={'customerKanaName'}>{data.contract.customerKanaName}</td>
      <td key={'customerPhoneNumber'}>{data.contract.customerPhoneNumber}</td>
    </tr>
  );
};

export default SearchResultItem;
