import React, { useEffect, useState } from 'react';
import { Modal, CircularProgress } from '@material-ui/core';
import { Button } from '../atoms/ButtonWithAuth';
import { IconButton } from '../atoms/IconButtonWithAuth';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import Redux from '../../redux/ReduxConnector';
import moment from 'moment';
import { ifBasePath, defaultProjectId } from '../../config/baseConfig';

import { getManagementCompany } from '../../utils/bmResidenceService';
import { BasicItemKeys } from '../../enums/item/basic-item-keys';
import { ApplicationCategory } from '../../enums/common/application-category';

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    container: {
      background: 'var(--color-white)',
      borderRadius: 10,
      textAlign: 'left',
      padding: '20px 32px',
      width: 460,
      position: 'relative'
    },
    closeButton: {
      position: 'absolute',
      fontSize: 20,
      fontWeight: 900,
      top: 10,
      right: 16
    },
    heading: {
      borderRadius: '10px 10px 0 0',
      color: 'var(--color-text)',
      fontSize: 20,
      fontWeight: 500,
      lineHeight: '29px',
      letterSpacing: '0.5px'
    },
    desc: {
      fontSize: 14,
      color: 'var(--color-text)',
      lineHeight: '20px',
      marginTop: 16
    },
    buttonsContainer: {
      marginTop: 20,
      textAlign: 'right'
    },
    cancelButton: {
      borderRadius: 999,
      padding: '8px 20px',
      color: 'var(--color-gray-3)',
      backgroundColor: 'var(--color-white)',
      marginRight: 16,
      '&:hover': {
        backgroundColor: '#eee'
      }
    },
    submitButton: {
      backgroundColor: '#EB5757',
      borderRadius: 999,
      padding: '8px 20px',
      color: 'var(--color-white)',
      '&:hover': {
        backgroundColor: '#ce4040'
      }
    },
    progress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translateY(-50%) translateX(-50%)'
    }
  })
);

interface Props {
  isOpen: boolean;
  setIsOpen: (e: boolean) => void;
  detectError: () => void;

  all_space_id: string;
  contract_id: string;
  terminate_date: string | undefined;
  move_plan_date: number;
  contractStartDate: string;
  terminateDate: string | undefined;
}

const TerminateSubmitModal: React.FC<Props> = props => {
  const {
    all_space_id,
    contract_id,
    terminate_date,
    move_plan_date,
    contractStartDate,
    terminateDate
  } = props;

  const styles = useStyles({});
  const history = useHistory();
  const [is2YearPassed, setIs2YearPassed] = useState<boolean>(false);
  const [cancelPlanDate, setCancelPlanDate] = useState<string>('');
  const [progress, setProgress] = useState<boolean>(false);

  const handleSubmit = React.useCallback(
    (isAfter2Year: boolean) => async () => {
      setProgress(true);
      const method = 'POST';
      const bitlockManageEndpoint = `${ifBasePath}application/cancel-contract`;
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-api-key': Redux.getStore().user.accessToken
      };
      const manageInfo = await getManagementCompany(
        all_space_id,
        Redux.getStore().user.accessToken
      );

      const body = JSON.stringify({
        [BasicItemKeys.ProjectId]: defaultProjectId,
        [BasicItemKeys.ApplicationCategory]: ApplicationCategory.CancelContract,
        [BasicItemKeys.AllSpaceId]: all_space_id,
        [BasicItemKeys.ContractId]: contract_id,
        [BasicItemKeys.CancelContractDate]: terminate_date,
        [BasicItemKeys.IsOverServiceStopDate]: isAfter2Year,
        [BasicItemKeys.OccupyScheduledDate]: moment(move_plan_date).format(
          'YYYY-MM-DD'
        ),
        [BasicItemKeys.RefreshToken]: Redux.getStore().user.refreshToken,
        [BasicItemKeys.ManagementCompanyName]:
          manageInfo.data && manageInfo.data.managementCompanyName
            ? manageInfo.data.managementCompanyName
            : '',
        [BasicItemKeys.ManagementCompanyAddress]:
          manageInfo.data && manageInfo.data.managementCompanyAddress
            ? manageInfo.data.managementCompanyAddress
            : '',
        [BasicItemKeys.ManagementCompanyPhoneNumber]:
          manageInfo.data && manageInfo.data.managementCompanyPhoneNumber
            ? manageInfo.data.managementCompanyPhoneNumber
            : '',
        [BasicItemKeys.NotificationEmail]:
          manageInfo.data && manageInfo.data.notificationEmail
            ? manageInfo.data.notificationEmail
            : ''
      });

      await fetch(bitlockManageEndpoint, {
        method,
        headers,
        body,
        mode: 'cors'
      })
        .then(res => {
          if (!res.ok) {
            throw Error(`${res.status}`);
          }
          setProgress(false);
          history.push('/processing');
        })
        .catch(error => {
          if (error.status !== 409) {
            console.error(error);
          }
          console.error(error);
          props.detectError();
          setProgress(false);
          props.setIsOpen(false);
        });
    },
    [all_space_id, contract_id, terminate_date, props, history, move_plan_date]
  );

  useEffect(() => {
    // 入居日から２年経過しているかどうか確認
    setIs2YearPassed(
      moment(terminateDate).diff(moment(contractStartDate), 'years') >= 2
    );
    // 入居日から２年経過しているかどうか確認
    setCancelPlanDate(
      moment(contractStartDate)
        .add(2, 'year')
        .subtract(1, 'day')
        .format('YYYY年M月D日')
    );
  }, [terminateDate, contractStartDate]);

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={props.isOpen}
      className={styles.modal}
      onClose={() => props.setIsOpen(false)}
    >
      <div className={styles.container}>
        <IconButton
          className={styles.closeButton}
          onClick={() => props.setIsOpen(false)}
        >
          <Close />
        </IconButton>
        <h2 className={styles.heading}>解約受付を行ってもよろしいですか？</h2>
        {is2YearPassed && (
          <p className={styles.desc}>
            スマモル賃貸プラン申込日から2年以上経過しているため、解約に伴い現在利用中のサービスを全て停止します。
          </p>
        )}
        {!is2YearPassed && (
          <p className={styles.desc}>
            入居から2年未満の契約です。
            <br />
            電気契約を解約した場合でも、
            <br />
            ・bitlock
            <br />
            ・ベネフィット・ワン
            <br />
            ・大阪ガスセキュリティサービス
            <br />
            のサービスは、{cancelPlanDate}まで継続利用可能です。
          </p>
        )}
        <div className={styles.buttonsContainer}>
          <Button
            className={styles.cancelButton}
            onClick={() => props.setIsOpen(false)}
            disabled={progress}
          >
            キャンセル
          </Button>
          {is2YearPassed && (
            <Button
              className={styles.submitButton}
              onClick={handleSubmit(true)}
              disabled={progress || !terminateDate}
            >
              解約
            </Button>
          )}
          {!is2YearPassed && (
            <Button
              className={styles.submitButton}
              onClick={handleSubmit(false)}
              disabled={progress || !terminateDate}
            >
              解約
            </Button>
          )}
        </div>
        {progress && (
          <div className={styles.progress}>
            <CircularProgress />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default TerminateSubmitModal;
