import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import OperationEditModal from './OperationEditModal';
import { Button } from '../atoms/ButtonWithAuth';
import moment from 'moment';
import { hasCorrectDataAuth } from '../../utils/auth-controler';
import { BasicItemKeys } from '../../enums/item/basic-item-keys';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      background: 'var(--color-white)',
      borderRadius: 16,
      padding: '4px 32px 32px',
      boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.2)',
      width: 1152,
      margin: '12px auto 0',
      textAlign: 'left'
    },
    header: {
      marginTop: 32,
      height: 40,
      color: 'var(--color-admin-key)',
      fontSize: 20,
      letterSpacing: '0.05em',
      fontWeight: 'bold',
      borderBottom: '1px solid #E0E0E0',
      position: 'relative'
    },
    editButton: {
      position: 'absolute',
      right: 0
    },
    table: {
      borderCollapse: 'collapse'
    },
    tr: {
      height: 48,
      border: '1px solid #E0E0E0',
      borderWidth: '0px 0px 1px',
      borderCollapse: 'collapse',
      textAlign: 'left',
      '& td': {
        height: 48,
        color: 'var(--color-text)',
        fontSize: 14,
        border: '1px solid #E0E0E0',
        borderCollapse: 'collapse',
        borderWidth: '0px 0px 1px'
      }
    },
    title: {
      width: 280,
      color: '#9E9E9E'
    },
    text: {
      width: 932
    }
  })
);

type P = {
  algoliaResponse: any;
  detectError: () => void;
  resetError: () => void;
};

const OperationCard: React.FC<P> = (props: P) => {
  const { algoliaResponse } = props;
  const styles = useStyles({});

  const [ismodalOpen, setIsModalOpen] = useState(false);
  const [appliedDate, setAppliedDate] = useState<string>('---');
  const [powerStartDate, setPowerStartDate] = useState<string>('---');
  const [contractStartDate, setContractStartDate] = useState<string>('---');
  // const [contractPeriod, setContractPeriod] = useState<string>('---');

  const handleClick = () => {
    props.resetError();
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (algoliaResponse) {
      // ToDo 使用する日付を確認する
      const applicationDate = moment(
        algoliaResponse[BasicItemKeys.NewApplicationCreatedAt]
      );
      const powerStartDateObj = moment(
        algoliaResponse[BasicItemKeys.ElectricityStartDate]
      );
      const contractStartDateObj = moment(
        algoliaResponse[BasicItemKeys.OccupyScheduledDate]
      );
      setAppliedDate(applicationDate.format('YYYY年M月D日'));
      setPowerStartDate(
        algoliaResponse.start_power_date
          ? powerStartDateObj.format('YYYY年M月D日')
          : '---'
      );
      setContractStartDate(contractStartDateObj.format('YYYY年M月D日'));
      // setContractPeriod(`${moment().diff(contractStartDateObj, 'months')}カ月`);
    }
  }, [algoliaResponse]);

  console.log('algoliaResponse', JSON.stringify(algoliaResponse));

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        契約者情報
        {hasCorrectDataAuth() && (
          <Button className={styles.editButton} onClick={handleClick}>
            変更
          </Button>
        )}
      </div>
      {algoliaResponse && (
        <React.Fragment>
          <table className={styles.table}>
            <tbody>
              <OperationItem
                title={'契約者氏名'}
                text={`${algoliaResponse[BasicItemKeys.CustomerLastName] ||
                  ''}　${algoliaResponse[BasicItemKeys.CustomerFirstName] ||
                  ''}`}
              />
              <OperationItem
                title={'契約者氏名カナ'}
                text={`${algoliaResponse[BasicItemKeys.CustomerLastNameKana] ||
                  ''}　${algoliaResponse[BasicItemKeys.CustomerFirstNameKana] ||
                  ''}`}
              />
              <OperationItem
                title={'電話番号'}
                text={algoliaResponse[BasicItemKeys.CustomerPhoneNumber] || ''}
              />
              <OperationItem
                title={'契約住所'}
                text={`〒${algoliaResponse[BasicItemKeys.PostCode] ||
                  ''} ${algoliaResponse[BasicItemKeys.Address1] ||
                  ''}${algoliaResponse[BasicItemKeys.Address2] || ''}`}
              />
              <OperationItem
                title={'メールアドレス'}
                text={algoliaResponse[BasicItemKeys.CustomerEmail] || ''}
              />
            </tbody>
          </table>
          <div className={styles.header}>契約詳細</div>
          <table className={styles.table}>
            <tbody>
              <OperationItem
                title={'契約内容'}
                text={
                  algoliaResponse[BasicItemKeys.HasElectricContract]
                    ? 'スマモル賃貸プラン(電気利用あり)'
                    : 'スマモル賃貸サービス(電気利用なし)'
                }
              />
              <OperationItem title={'申込日'} text={appliedDate || ''} />
              {algoliaResponse.start_power_date && (
                <OperationItem
                  title={'電気利用開始日'}
                  text={powerStartDate || ''}
                />
              )}
              <OperationItem
                title={'サービス利用開始日'}
                text={contractStartDate || ''}
              />
            </tbody>
          </table>
          <OperationEditModal
            isOpen={ismodalOpen}
            setIsOpen={e => setIsModalOpen(e)}
            originData={algoliaResponse}
            detectError={props.detectError}
          />
        </React.Fragment>
      )}
    </div>
  );
};

const OperationItem = (props: { title: string; text: string }) => {
  const styles = useStyles({});
  const { title, text } = props;

  return (
    <tr className={styles.tr}>
      <td className={styles.title}>{title}</td>
      <td className={styles.text}>{text}</td>
    </tr>
  );
};

export default OperationCard;
