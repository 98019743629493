// よく使用される項目のキー(カラム名)をここで定義する
// 各サービスごとに個別に設定される項目はDBのみ管理としソース上で管理しなくて良いと思うが、
// ある程度共通的に使用する項目はカラム名の記載ミス等を極力排除するために明示的に記載する
export enum BasicItemKeys {
  ProjectId = 'projectId',
  ApplicationId = 'applicationId',
  ApplicationDate = 'applicationDate',

  // 認証関連
  ApiKey = 'apiKey',
  RefreshToken = 'refreshToken',

  // TaKuTyのサーバーサイド内で生成される情報
  LinkageId = 'linkageId',
  ReferenceDate = 'referenceDate',
  ServiceStopDate = 'serviceStopDate',

  // BKPログインにて取得できる情報
  BkpUserId = 'bkpUserId',
  BkpEmail = 'bkpEmail',
  BkpPhoneNumber = 'bkpPhoneNumber',

  // bMからURL発行時点で確定している情報
  HasElectricContract = 'hasElectricContract',
  OrganizationId = 'organizationId',
  BuildingName = 'buildingName',
  AllSpaceId = 'allSpaceId',
  ContractId = 'contractId',
  PostCode = 'postCode',
  City = 'city',
  AfterCity = 'afterCity',
  Address1 = 'address1',
  Address2 = 'address2',
  PropertyId = 'propertyId',
  PropertyServiceCode = 'propertyServiceCode',
  PropertyCode = 'propertyCode',
  Prefecture = 'prefecture',
  PropertyName = 'propertyName',
  OccupyScheduledDate = 'occupyScheduledDate',

  ApplicationCategory = 'applicationCategory',
  UseGasPlanSelection = 'useGasPlanSelection',
  ElectricityStartDate = 'electricityStartDate',
  // ElectricityOptionPlanSelection = 'electricityOptionPlanSelection',
  // ElectricityBasePlanSelection = 'electricityBasePlanSelection',
  GasStartDateTime = 'gasStartDateTime',
  GasStartDate = 'gasStartDate',
  GasStartTimeSelection = 'gasStartTimeSelection',
  // WitnessGasStart = 'witnessGasStart',
  // WitnessNameGasStart = 'witnessNameGasStart',
  PhoneNumberForGasStart = 'phoneNumberForGasStart',
  // GasBasePlanSelection = 'gasBasePlanSelection',
  // GasOptionPlanSelection = 'gasOptionPlanSelection',

  CustomerName = 'customerName',
  CustomerNameKana = 'customerNameKana',
  // CustomerNamePrefix = 'customer',
  CustomerLastName = 'customerLastName',
  CustomerFirstName = 'customerFirstName',
  CustomerLastNameKana = 'customerLastNameKana',
  CustomerFirstNameKana = 'customerFirstNameKana',
  CustomerBirthDay = 'customerBirthDay',
  CustomerPhoneNumber = 'customerPhoneNumber',
  CustomerGender = 'customerGender',
  CustomerEmail = 'customerEmail',
  // CustomerEmailInput = 'customerEmailInput',
  // CustomerEmailConfirmation = 'customerEmailConfirmation',
  PaymentMethodSelection = 'paymentMethodSelection',
  // AgreeThePolicy = 'agreeThePolicy'

  IsModifiedInSameDay = 'isModifiedInSameDay',
  IsCanceledInSameDay = 'isCanceledInSameDay',
  IsLatest = 'isLatest',

  IsOverServiceStopDate = 'isOverServiceStopDate',
  CancelContractDate = 'cancelContractDate',

  NotificationContentsType = 'notificationContentsType',
  NotificationToolType = 'notificationToolType',

  BenefitOneInitialPassword = 'benefitOneInitialPassword',
  AllSpaceIdAndContractId = 'allSpaceIdAndContractId',
  NewApplicationCreatedAt = 'newApplicationCreatedAt',

  ManagementCompanyName = 'managementCompanyName',
  ManagementCompanyAddress = 'managementCompanyAddress',
  ManagementCompanyPhoneNumber = 'managementCompanyPhoneNumber',
  NotificationEmail = 'notificationEmail'
}
