import React from 'react';
import AppContainer from '../components/organisms/AppContainer';
import AggregationTemplate from '../components/template/AggregationTemplate';

const AggregationScreen: React.FC = () => {
  return (
    <AppContainer>
      <AggregationTemplate />
    </AppContainer>
  );
};

export default AggregationScreen;
