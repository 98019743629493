import React from 'react';
import AppContainer from '../components/organisms/AppContainer';
import IndexTemplate from '../components/template/IndexTemplate';

const IndexScreen: React.FC = () => {
  return (
    <AppContainer>
      <IndexTemplate />
    </AppContainer>
  );
};

export default IndexScreen;
