import React from 'react';
import AppContainer from '../components/organisms/AppContainer';
import TerminateTemplate from '../components/template/TerminateTemplate';

const TerminateScreen: React.FC = () => {
  return (
    <AppContainer>
      <TerminateTemplate />
    </AppContainer>
  );
};

export default TerminateScreen;
