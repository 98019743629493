import req from 'request-promise';
import { ifBasePath } from '../../config/baseConfig';
import Redux from '../../redux/ReduxConnector';
import LocalStorage, { LocalStorageKey } from '../../utils/LocalStorage';

export const getAggregationByAggregateDate = async (
  aggregateDate: string
): Promise<Array<any>> => {
  const options: any = {
    method: 'GET',
    uri: `${ifBasePath}tenant/1853391b-a49d-4232-b0e1-c310a904a394/aggregation/date/${aggregateDate}`,
    timeout: 30 * 1000, // タイムアウト指定しないと帰ってこない場合がある
    headers: {
      'x-api-key':
        Redux.getStore().user.accessToken ||
        LocalStorage.get(LocalStorageKey.ACCESS_TOKEN)
      // 'refresh-token':
      // Redux.getStore().user.refreshToken ||
      // LocalStorage.get(LocalStorageKey.REFRESH_TOKEN)
    }
  };

  const result = await req(options);

  console.log('end getAggregationByAggregateDate');
  console.log(result);
  return result;
};
