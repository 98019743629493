import React, { useState } from 'react';
import { Modal, CircularProgress } from '@material-ui/core';
import { Button } from '../atoms/ButtonWithAuth';
import { IconButton } from '../atoms/IconButtonWithAuth';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { CustomTextField } from '../atoms/TextFieldWithAuth';
import { CustomDateSelect } from '../atoms/CustomDateSelect';
import Redux from '../../redux/ReduxConnector';
import { useHistory } from 'react-router-dom';
import { ifBasePath, defaultProjectId } from '../../config/baseConfig';

import { getManagementCompany } from '../../utils/bmResidenceService';
import { BasicItemKeys } from '../../enums/item/basic-item-keys';
import { ApplicationCategory } from '../../enums/common/application-category';

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    container: {
      background: 'var(--color-white)',
      borderRadius: 10,
      width: 648,
      height: 'auto',
      margin: '4px auto 0',
      textAlign: 'left'
    },
    header: {
      borderRadius: '10px 10px 0 0',
      height: 64,
      backgroundColor: 'var(--color-admin-key)',
      position: 'relative',
      '& h2': {
        textAlign: 'left',
        color: 'var(--color-white)',
        fontSize: 20,
        marginLeft: 20,
        lineHeight: '64px',
        fontWeight: 500,
        letterSpacing: '0.5px'
      }
    },
    closeButton: {
      position: 'absolute',
      top: 8,
      right: 8,
      color: 'var(--color-white)'
    },
    formContainer: {
      padding: '10px 20px'
    },
    halfTextField: {
      width: 300,
      margin: '6px 8px 0 0',
      '& input': {
        fontSize: 14
      }
    },
    seconfHalfTextField: {
      width: 300,
      margin: '6px 0 0',
      '& input': {
        fontSize: 14
      }
    },
    isChanged: {
      backgroundColor: '#DEF2FF'
    },
    lognTextField: {
      width: 608,
      margin: '6px 0 0',
      '& input': {
        fontSize: 14
      }
    },
    label: {
      display: 'block',
      color: 'var(--color-gray-3)',
      fontSize: 12,
      marginTop: 16
    },
    buttonsContainer: {
      marginTop: 16,
      borderTop: '1px solid #E0E0E0',
      textAlign: 'right',
      padding: 20
    },
    cancelButton: {
      padding: '8px 28px',
      borderRadius: 999,
      backgroundColor: 'var(--color-white)',
      color: 'var(--color-gray-3)',
      marginRight: 8,
      '&:hover': {
        backgroundColor: '#f7f7f7'
      }
    },
    submitButton: {
      padding: '8px 28px',
      borderRadius: 999,
      backgroundColor: 'var(--color-admin-key)',
      color: 'var(--color-white)',
      '&:disabled': {
        backgroundColor: '#e0e0e0'
      },
      '&:hover': {
        backgroundColor: '#1f6fda'
      }
    },
    progress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translateY(-50%) translateX(-50%)'
    }
  })
);

interface Props {
  isOpen: boolean;
  setIsOpen: (e: boolean) => void;
  originData: any;
  detectError: () => void;
}

enum InputItemType {
  CustomerLastName = 'FIRST_NAME',
  CustomerFirstName = 'LAST_NAME',
  CustomerLastNameKana = 'FIRST_NAME_KANA',
  CustomerFirstNameKana = 'LAST_NAME_KANA',
  CustomerBirthDay = 'BIRTH_DAY',
  CustomerPhoneNumber = 'TELL',
  CustomerEmail = 'MAIL'
}

const OperationEditModal: React.FC<Props> = props => {
  const { originData } = props;

  const styles = useStyles({});
  const history = useHistory();
  const [progress, setProgress] = React.useState<boolean>(false);

  const [parameter, setParameter] = React.useState({
    [InputItemType.CustomerLastName]:
      originData[BasicItemKeys.CustomerLastName],
    [InputItemType.CustomerFirstName]:
      originData[BasicItemKeys.CustomerFirstName],
    [InputItemType.CustomerLastNameKana]:
      originData[BasicItemKeys.CustomerLastNameKana],
    [InputItemType.CustomerFirstNameKana]:
      originData[BasicItemKeys.CustomerFirstNameKana],
    [InputItemType.CustomerBirthDay]:
      originData[BasicItemKeys.CustomerBirthDay],
    [InputItemType.CustomerPhoneNumber]:
      originData[BasicItemKeys.CustomerPhoneNumber],
    [InputItemType.CustomerEmail]: originData[BasicItemKeys.CustomerEmail]
  });

  const updateInputParameter = React.useCallback(
    (type: InputItemType) => (e: any): void => {
      setParameter({
        ...parameter,
        [type]: e.target.value
      });
    },
    [parameter, setParameter]
  );

  const getInputParameter = React.useCallback(
    (type: InputItemType): string | undefined => {
      return parameter[type] && parameter[type].length > 0
        ? parameter[type]
        : undefined;
    },
    [parameter]
  );

  const [birthDayErrorMessage, setBirthDayErrorMessage] = useState('');
  const [phoneNumErrorMessage, setPhoneNumErrorMessage] = useState('');
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState('');
  const [familyNameErrorMessage, setFamilyNameErrorMessage] = useState('');
  const [firstNameKanaErrorMessage, setFirstNameKanaErrorMessage] = useState(
    ''
  );
  const [familyNameKanaErrorMessage, setFamilyNameKanaErrorMessage] = useState(
    ''
  );
  const [mailErrorMessage, setMailErrorMessage] = useState('');

  const birthRegex = /^(?!([02468][1235679]|[13579][01345789])000229)(([0-9]{4}(01|03|05|07|08|10|12)(0[1-9]|[12][0-9]|3[01]))|([0-9]{4}(04|06|09|11)(0[1-9]|[12][0-9]|30))|([0-9]{4}02(0[1-9]|1[0-9]|2[0-8]))|([0-9]{2}([02468][048]|[13579][26])0229))$/;
  const phoneRegex = /^0{1}\d{8,13}$/;
  const onlyNumber = /^\d+$/;
  // eslint-disable-next-line no-control-regex
  const zenkaku = /^[^\x01-\x7E\uFF65-\uFF9F]+$/; // 全角の表現（半角カナを取り除く）。他表現が見つからない為、ここでの制御文字は許可
  const zenkakuKana = /^[\u30a1-\u30f6]+$/;
  const mailRegex = /^[A-Za-z0-9]{1}[A-Za-z0-9+_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;

  const handleBirthDay = (e: any) => {
    const birthDay = e.target.value;
    // if (birthDay === '') {
    //   setBirthDayErrorMessage('入力してください');
    // } else if (!birthDay.match(birthRegex)) {
    //   setBirthDayErrorMessage('YYYYMMDD形式で入力してください');
    // } else {
    //   setBirthDayErrorMessage('');
    // }
    updateInputParameter(InputItemType.CustomerBirthDay)(e);
  };

  const handlePhoneNumber = (e: any) => {
    const phoneNumber = e.target.value;
    if (phoneNumber === '') {
      setPhoneNumErrorMessage('入力してください');
    } else if (phoneNumber.match(zenkaku)) {
      setPhoneNumErrorMessage('半角数字で入力してください');
    } else if (!onlyNumber.test(phoneNumber)) {
      setPhoneNumErrorMessage('半角数字で入力してください');
    } else if (!phoneRegex.test(phoneNumber)) {
      setPhoneNumErrorMessage('有効な電話番号を入力してください');
    } else {
      setPhoneNumErrorMessage('');
    }
    updateInputParameter(InputItemType.CustomerPhoneNumber)(e);
  };

  const checkFirstNameError = (e: any) => {
    const firstName = e.target.value;
    if (firstName === '') {
      setFirstNameErrorMessage('入力してください');
    } else if (!firstName.match(zenkaku)) {
      setFirstNameErrorMessage('全角で入力してください');
    } else if (firstName.length > 10) {
      setFirstNameErrorMessage('10文字以内で入力してください');
    } else {
      setFirstNameErrorMessage('');
    }
    updateInputParameter(InputItemType.CustomerFirstName)(e);
  };

  const checkFamilyNameError = (e: any) => {
    const familyName = e.target.value;
    if (familyName === '') {
      setFamilyNameErrorMessage('入力してください');
    } else if (!familyName.match(zenkaku)) {
      setFamilyNameErrorMessage('全角で入力してください');
    } else if (familyName.length > 10) {
      setFamilyNameErrorMessage('10文字以内で入力してください');
    } else {
      setFamilyNameErrorMessage('');
    }
    updateInputParameter(InputItemType.CustomerLastName)(e);
  };

  const checkFirstNameKanaError = (e: any) => {
    const firstNameKana = e.target.value;
    if (firstNameKana === '') {
      setFirstNameKanaErrorMessage('入力してください');
    } else if (!firstNameKana.match(zenkakuKana)) {
      setFirstNameKanaErrorMessage('全角カナで入力してください');
    } else if (firstNameKana.length > 10) {
      setFirstNameKanaErrorMessage('10文字以内で入力してください');
    } else if (
      firstNameKana.length +
        (parameter.FIRST_NAME_KANA ? parameter.FIRST_NAME_KANA.length : '') >
      14
    ) {
      setFirstNameKanaErrorMessage(
        'フリガナは合計14文字以内で入力してください'
      );
    } else {
      setFirstNameKanaErrorMessage('');
    }
    updateInputParameter(InputItemType.CustomerFirstNameKana)(e);
  };

  const checkFamilyNameKanaError = (e: any) => {
    const familyNameKana = e.target.value;
    if (familyNameKana === '') {
      setFamilyNameKanaErrorMessage('入力してください');
    } else if (!familyNameKana.match(zenkakuKana)) {
      setFamilyNameKanaErrorMessage('全角カナで入力してください');
    } else if (familyNameKana.length > 10) {
      setFamilyNameKanaErrorMessage('10文字以内で入力してください');
    } else if (
      familyNameKana.length +
        (parameter.LAST_NAME_KANA ? parameter.LAST_NAME_KANA.length : '') >
      14
    ) {
      setFamilyNameKanaErrorMessage(
        'フリガナは合計14文字以内で入力してください'
      );
    } else {
      setFamilyNameKanaErrorMessage('');
    }
    updateInputParameter(InputItemType.CustomerLastNameKana)(e);
  };

  const handleEmail = (e: any) => {
    const email = e.target.value;
    if (email === '') {
      setMailErrorMessage('');
    } else if (email.length > 60) {
      setMailErrorMessage('60文字以内で入力してください');
    } else if (!email.match(mailRegex)) {
      setMailErrorMessage('有効なメールアドレスを入力してください');
    } else {
      setMailErrorMessage('');
    }
    updateInputParameter(InputItemType.CustomerEmail)(e);
  };

  const handleBack = React.useCallback(() => {
    props.setIsOpen(false);
  }, [props]);
  const handleChange = React.useCallback(async () => {
    setProgress(true);
    const method = 'POST';
    const bitlockManageEndpoint = `${ifBasePath}application/update`;
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-api-key': Redux.getStore().user.accessToken
    };
    const apiKey = Redux.getStore().user.accessToken;
    const allSpaceId = originData.base.id;
    const manageInfo = await getManagementCompany(allSpaceId, apiKey);

    const body = JSON.stringify({
      [BasicItemKeys.ProjectId]: defaultProjectId,
      [BasicItemKeys.ApplicationCategory]:
        ApplicationCategory.UpdateApplication,
      [BasicItemKeys.AllSpaceId]: originData.base.id,
      [BasicItemKeys.ContractId]: originData.contract.contractId,
      [BasicItemKeys.CustomerLastName]:
        originData[BasicItemKeys.CustomerLastName] !==
        getInputParameter(InputItemType.CustomerLastName)
          ? getInputParameter(InputItemType.CustomerLastName)
          : undefined,
      [BasicItemKeys.CustomerFirstName]:
        originData[BasicItemKeys.CustomerFirstName] !==
        getInputParameter(InputItemType.CustomerFirstName)
          ? getInputParameter(InputItemType.CustomerFirstName)
          : undefined,
      [BasicItemKeys.CustomerLastNameKana]:
        originData[BasicItemKeys.CustomerLastNameKana] !==
        getInputParameter(InputItemType.CustomerLastNameKana)
          ? getInputParameter(InputItemType.CustomerLastNameKana)
          : undefined,
      [BasicItemKeys.CustomerFirstNameKana]:
        originData[BasicItemKeys.CustomerFirstNameKana] !==
        getInputParameter(InputItemType.CustomerFirstNameKana)
          ? getInputParameter(InputItemType.CustomerFirstNameKana)
          : undefined,
      [BasicItemKeys.CustomerBirthDay]:
        originData[BasicItemKeys.CustomerBirthDay] !==
        getInputParameter(InputItemType.CustomerBirthDay)
          ? getInputParameter(InputItemType.CustomerBirthDay)
          : undefined,
      [BasicItemKeys.CustomerPhoneNumber]:
        originData[BasicItemKeys.CustomerPhoneNumber] !==
        getInputParameter(InputItemType.CustomerPhoneNumber)
          ? getInputParameter(InputItemType.CustomerPhoneNumber)
          : undefined,
      [BasicItemKeys.CustomerEmail]:
        originData[BasicItemKeys.CustomerEmail] !==
        getInputParameter(InputItemType.CustomerEmail)
          ? getInputParameter(InputItemType.CustomerEmail)
          : undefined,
      [BasicItemKeys.RefreshToken]: Redux.getStore().user.refreshToken,
      [BasicItemKeys.ManagementCompanyName]:
        manageInfo.data && manageInfo.data.managementCompanyName
          ? manageInfo.data.managementCompanyName
          : undefined,
      [BasicItemKeys.ManagementCompanyAddress]:
        manageInfo.data && manageInfo.data.managementCompanyAddress
          ? manageInfo.data.managementCompanyAddress
          : undefined,
      [BasicItemKeys.ManagementCompanyPhoneNumber]:
        manageInfo.data && manageInfo.data.managementCompanyPhoneNumber
          ? manageInfo.data.managementCompanyPhoneNumber
          : undefined,
      [BasicItemKeys.NotificationEmail]:
        manageInfo.data && manageInfo.data.notificationEmail
          ? manageInfo.data.notificationEmail
          : ''
    });

    await fetch(bitlockManageEndpoint, {
      method,
      headers,
      body,
      mode: 'cors'
    })
      .then(res => {
        if (!res.ok) {
          throw Error(`${res.status}`);
        }
        history.go(0);
        // history.push('/processing');
        props.setIsOpen(false);
        setProgress(false);
      })
      .catch(error => {
        if (error.status !== 409) {
          console.error(error);
        }
        console.error(error);
        props.detectError();
        props.setIsOpen(false);
        setProgress(false);
      });
  }, [
    getInputParameter,
    history,
    originData.base.id,
    originData[BasicItemKeys.CustomerBirthDay],
    originData.contract.contractId,
    originData[BasicItemKeys.CustomerEmail],
    originData[BasicItemKeys.CustomerLastName],
    originData[BasicItemKeys.CustomerLastNameKana],
    originData[BasicItemKeys.CustomerFirstName],
    originData[BasicItemKeys.CustomerFirstName],
    originData[BasicItemKeys.CustomerPhoneNumber],
    props
  ]);

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={props.isOpen}
      className={styles.modal}
      onClose={() => props.setIsOpen(false)}
    >
      <div className={styles.container}>
        <header className={styles.header}>
          <h2>契約者情報の編集</h2>
          <IconButton
            className={styles.closeButton}
            onClick={() => props.setIsOpen(false)}
          >
            <Close />
          </IconButton>
        </header>
        <div className={styles.formContainer}>
          <div>
            <Label text={'氏名'} />
            <EditField
              placeholder={'姓'}
              defaultValue={parameter[InputItemType.CustomerLastName]}
              isChanged={
                parameter[InputItemType.CustomerLastName] !==
                originData[BasicItemKeys.CustomerLastName]
              }
              error={familyNameErrorMessage !== ''}
              helperText={familyNameErrorMessage}
              onChange={checkFamilyNameError}
            />
            <EditField
              placeholder={'名'}
              defaultValue={parameter[InputItemType.CustomerFirstName]}
              isChanged={
                parameter[InputItemType.CustomerFirstName] !==
                originData[BasicItemKeys.CustomerFirstName]
              }
              error={firstNameErrorMessage !== ''}
              helperText={firstNameErrorMessage}
              onChange={checkFirstNameError}
              second
            />
          </div>

          <div>
            <Label text={'フリガナ'} />
            <EditField
              placeholder={'セイ'}
              defaultValue={parameter[InputItemType.CustomerLastNameKana]}
              isChanged={
                parameter[InputItemType.CustomerLastNameKana] !==
                originData[BasicItemKeys.CustomerLastNameKana]
              }
              error={familyNameKanaErrorMessage !== ''}
              helperText={familyNameKanaErrorMessage}
              onChange={checkFamilyNameKanaError}
            />
            <EditField
              placeholder={'メイ'}
              defaultValue={parameter[InputItemType.CustomerFirstNameKana]}
              isChanged={
                parameter[InputItemType.CustomerFirstNameKana] !==
                originData[BasicItemKeys.CustomerFirstNameKana]
              }
              error={firstNameKanaErrorMessage !== ''}
              helperText={firstNameKanaErrorMessage}
              onChange={checkFirstNameKanaError}
              second
            />
          </div>
          <div>
            <Label text={'生年月日：'} />
            <CustomDateSelect
              initialValue={originData[BasicItemKeys.CustomerBirthDay]
                .replace(/\//g, '')
                .replace(/-/g, '')}
              value={parameter[InputItemType.CustomerBirthDay]
                .replace(/\//g, '')
                .replace(/-/g, '')}
              setValue={handleBirthDay}
            />
            {/* <EditField
              placeholder={'生年月日'}
              defaultValue={parameter[InputItemType.BIRTH_DAY]}
              isChanged={
                parameter[InputItemType.BIRTH_DAY] !== originData.birthday
              }
              error={birthDayErrorMessage !== ''}
              helperText={birthDayErrorMessage}
              onChange={handleBirthDay}
            /> */}
          </div>
          <div>
            <Label text={'電話番号'} />
            <EditField
              placeholder={'電話番号'}
              defaultValue={parameter[InputItemType.CustomerPhoneNumber]}
              isChanged={
                parameter[InputItemType.CustomerPhoneNumber] !==
                originData[BasicItemKeys.CustomerPhoneNumber]
              }
              error={phoneNumErrorMessage !== ''}
              helperText={phoneNumErrorMessage}
              onChange={handlePhoneNumber}
            />
          </div>
          <div>
            <Label text={'メールアドレス'} />
            <EditField
              placeholder={'メールアドレス'}
              defaultValue={parameter[InputItemType.CustomerEmail]}
              isChanged={
                parameter[InputItemType.CustomerEmail] !==
                originData[BasicItemKeys.CustomerEmail]
              }
              onChange={handleEmail}
              error={mailErrorMessage !== ''}
              helperText={mailErrorMessage}
              long
            />
          </div>
        </div>

        <div className={styles.buttonsContainer}>
          <Button
            className={styles.cancelButton}
            onClick={handleBack}
            disabled={progress}
          >
            キャンセル
          </Button>
          <Button
            className={styles.submitButton}
            onClick={handleChange}
            disabled={
              progress ||
              phoneNumErrorMessage !== '' ||
              firstNameErrorMessage !== '' ||
              familyNameErrorMessage !== '' ||
              firstNameKanaErrorMessage !== '' ||
              familyNameKanaErrorMessage !== '' ||
              mailErrorMessage !== '' ||
              birthDayErrorMessage !== ''
            }
          >
            変更
          </Button>
        </div>
        {progress && (
          <div className={styles.progress}>
            <CircularProgress />
          </div>
        )}
      </div>
    </Modal>
  );
};

const Label = (props: { text: string }) => {
  const styles = useStyles({});
  return <label className={styles.label}>{props.text}</label>;
};

const EditField = (props: {
  placeholder: string;
  long?: boolean;
  defaultValue?: string;
  second?: boolean;
  onChange?: any;
  isChanged?: boolean;
  error?: boolean;
  helperText?: string;
}) => {
  const styles = useStyles({});
  let className = props.long ? styles.lognTextField : styles.halfTextField;
  className = props.second ? styles.seconfHalfTextField : className;
  className =
    !props.error && props.isChanged
      ? `${className} ${styles.isChanged}`
      : className;

  return (
    <CustomTextField
      className={className}
      placeholder={props.placeholder}
      defaultValue={props.defaultValue}
      helperText={props.helperText}
      margin={'dense'}
      InputLabelProps={{
        shrink: true
      }}
      error={props.error}
      variant="outlined"
      onChange={props.onChange}
    />
  );
};

export default OperationEditModal;
