/**
 * スマモル賃貸サービスの種類です。
 *
 * OG域内: 大阪ガス様の管理内のうち関西圏内に適用可能なプラン
 * OG域内 電気のみ: 大阪ガス様の管理内のうち関西圏外に適用可能なプランで、電気のみのプラン
 * OG域外: 大阪ガス様の管理内のうち関西圏外(中部九州)に適用可能なプラン
 * CDE: CDE様の管理内に適用可能なプラン
 * CDE 電気のみ: CDE様の管理内に適用可能なプランで、電気のみのプラン
 * スマモルでない通常物件: スマモル組織においてスマモル賃貸サービスを適用しないプラン
 *
 * 以下は2024/04時点で運用されていないプラン
 * 京葉ガス: 京葉ガス様の管理内に適用可能なプラン
 * 西部ガス: 西部ガス様の管理内に適用可能なプラン
 *
 */
export enum SumamoruProjectType {
  OGInternal = 'OGInternal',
  OGInternalOnlyElectricity = 'OGInternal_OnlyElectricity', // OG域内 電気のみ
  CDE = 'CDE',
  CDEOnlyElectricity = 'CDE_OnlyElectricity', // CDE 電気のみ
  KeiyouGas = 'KeiyouGas',
  SaibuGas = 'SaibuGas',
  NotSumamoru = 'NotSumamoru',
  OGExternal = 'OGExternal'
};
