import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

import { CustomFab } from '../atoms/CustomFabButton';
import { CustomTextField } from '../atoms/CustomTextField';
import { useHistory } from 'react-router-dom';
import Redux from '../../redux/ReduxConnector';
import AuthService from '../../utils/BkpAuthService';
import LocalStorage, { LocalStorageKey } from '../../utils/LocalStorage';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      backgroundColor: 'var(--color-admin-key)',
      backdropFilter: 'blur(30px)',
      minHeight: '100vh',
      position: 'relative'
    },
    card: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translateY(-50%) translateX(-50%)',
      background: 'rgba(255, 255, 255, 0.8)',
      boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
      borderRadius: '12px',
      width: '360px',
      height: '540px'
    },
    submitButton: {
      marginTop: 80,
      width: '296px !important',
      '&:hover': {
        boxShadow:
          '0px 2px 15px rgba(0, 0, 0, 0.2), 0px 3px 6px rgba(0, 0, 0, 0.17), 0px 2px 4px rgba(0, 0, 0, 0.15)'
      }
    },
    caption: {
      color: 'var(--color-gray-3)',
      marginTop: 84,
      fontSize: 14,
      fontWeight: 'bold'
    },
    logo: {
      color: 'var(--color-admin-key)',
      fontSize: 24,
      fontWeight: 900,
      fontStyle: 'normal',
      fontFamily: 'Lato, sans-serif',
      margin: '20px 0 64px'
    },
    errorMessage: {
      color: 'var(--color-error)',
      fontSize: 14
    },
    textField: {
      width: 296,
      height: 40,
      '& input': {
        background: 'white'
      },
      '& p': {
        fontWeight: 'bold',
        color: 'var(--color-error) !important',
        margin: '8px 0 0 !important'
      }
    },
    progress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translateY(-50%) translateX(-50%)'
    }
  })
);

const SigninTemplate: React.FC = () => {
  const styles = useStyles({});
  const history = useHistory();

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [progress, setProgress] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  // 遷移前画面から引き継がれてきたエラーメッセージ。この画面で操作があった場合は表示したくない
  const [errMessageFromReferrer, setErrMessageFromReferrer] = React.useState(
    ''
  );

  React.useEffect(() => {
    if (history.location.state && history.location.state.isTimeOut) {
      // 連続ログイン可能時間を超過した強制ログアウトによる遷移時
      setErrMessageFromReferrer('長時間未操作のため再度ログインしてください');
    }
  }, [history]);

  const submitLogin = React.useCallback(() => {
    setIsError(false);
    setProgress(true);
    AuthService.loginWithEmail(email, password)
      .then(data => {
        const now = Date.now();
        Redux.actions.user.setUser({ ...data, operationTimestamp: now } as any);
        LocalStorage.set(LocalStorageKey.ACCESS_TOKEN, data.accessToken);
        LocalStorage.set(LocalStorageKey.USER_ID, data.userId);
        LocalStorage.set(LocalStorageKey.REFRESH_TOKEN, data.refreshToken);
        LocalStorage.set(LocalStorageKey.AUTH_TYPE, data.authType);
        LocalStorage.set(LocalStorageKey.OPERATION_TIMESTAMP, now.toString());
        history.push('/');
      })
      .catch(error => {
        console.error(error);
        setIsError(true);
        setProgress(false);
      });
  }, [email, password, history]);

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <p className={styles.caption}>Welcome to</p>
        <p className={styles.logo}>スマモル賃貸サービス管理</p>
        {isError && <p className={styles.errorMessage}>認証に失敗しました。</p>}
        {!isError && errMessageFromReferrer && (
          <p className={styles.errorMessage}>{errMessageFromReferrer}</p>
        )}
        <CustomTextField
          className={styles.textField}
          placeholder="メールアドレス"
          margin="normal"
          InputLabelProps={{
            shrink: true
          }}
          variant="outlined"
          onChange={e => setEmail(e.target.value)}
          type={'email'}
        />
        <CustomTextField
          className={styles.textField}
          placeholder="パスワード"
          margin="normal"
          InputLabelProps={{
            shrink: true
          }}
          variant="outlined"
          onChange={e => setPassword(e.target.value)}
          type={'password'}
        />
        <CustomFab
          variant={'extended'}
          size={'large'}
          aria-label={'add'}
          className={styles.submitButton}
          onClick={submitLogin}
          disabled={progress}
        >
          ログイン
        </CustomFab>
      </div>
      {progress && (
        <div className={styles.progress}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default SigninTemplate;
