import { withStyles } from '@material-ui/styles';
import { Fab } from './FabWithAuth';

export const CustomFab = withStyles({
  root: {
    '&.MuiFab-root': {
      backgroundColor: 'var(--color-admin-key)',
      color: 'var(--color-white)',
      width: '100%',
      boxShadow:
        '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14)',
      letterSpacing: '.75px',
      fontSize: 16,

      '&:hover': {
        backgroundColor: 'var(--color-admin-key)'
      },
      '&:disabled': {
        backgroundColor: 'var(--color-admin-key)',
        color: '#fff'
      }
    }
  }
})(Fab);
