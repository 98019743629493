import React from 'react';
import './App.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppState } from './utils/store';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  IndexScreen,
  SigninScreen,
  OperationScreen,
  TerminateScreen,
  ProcessingScreen,
  AggregationScreen
} from './screens';

import AllSpaceAccount from './components/algolia/account/all-space';
import { AlgoliaConfig } from './config/baseConfig';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';
import { AuthenticationController } from './components/atoms/AuthenticationController';

const useStyles = makeStyles(() =>
  createStyles({
    background: {
      background: 'var(--background-admin)',
      minHeight: '100vh',
      textAlign: 'center'
    }
  })
);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2f80ed'
    }
  }
});

const mapStateToProps = (state: AppState) => {
  return {
    entry: state.entry
  };
};

type Props = ReturnType<typeof mapStateToProps>;

AllSpaceAccount.init(
  AlgoliaConfig.AllSpace.applicationId,
  AlgoliaConfig.AllSpace.adminApiKey
);

const App: React.FC<Props> = props => {
  const styles = useStyles({});

  return (
    <div className={styles.background}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Switch>
            <Route exact path={'/signin'} component={SigninScreen} />
            <AuthenticationController>
              <Route exact path={'/operation'} component={OperationScreen} />
              <Route exact path={'/terminate'} component={TerminateScreen} />
              <Route exact path={'/processing'} component={ProcessingScreen} />
              <Route
                exact
                path={'/aggregation'}
                component={AggregationScreen}
              />
              <Route exact path={'/'} component={IndexScreen} />
            </AuthenticationController>
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
};

export default connect(mapStateToProps, {})(App);
