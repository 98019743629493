import React from 'react';
import { Modal, CircularProgress } from '@material-ui/core';
import { Button } from '../atoms/ButtonWithAuth';
import { IconButton } from '../atoms/IconButtonWithAuth';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import Redux from '../../redux/ReduxConnector';
import { ifBasePath, defaultProjectId } from '../../config/baseConfig';
import { getManagementCompany } from '../../utils/bmResidenceService';
import MessageModal from '../molecules/MessageModal';
import { BasicItemKeys } from '../../enums/item/basic-item-keys';
import { ApplicationCategory } from '../../enums/common/application-category';

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    container: {
      background: 'var(--color-white)',
      borderRadius: 10,
      textAlign: 'left',
      padding: '20px 32px',
      width: 460,
      position: 'relative'
    },
    closeButton: {
      position: 'absolute',
      fontSize: 20,
      fontWeight: 900,
      top: 10,
      right: 16
    },
    heading: {
      borderRadius: '10px 10px 0 0',
      color: 'var(--color-text)',
      fontSize: 20,
      fontWeight: 500,
      lineHeight: '29px',
      letterSpacing: '0.5px'
    },
    desc: {
      fontSize: 14,
      color: 'var(--color-text)',
      lineHeight: '20px',
      marginTop: 16
    },
    buttonsContainer: {
      marginTop: 20,
      textAlign: 'right'
    },
    cancelButton: {
      borderRadius: 999,
      padding: '8px 20px',
      color: 'var(--color-gray-3)',
      backgroundColor: 'var(--color-white)',
      marginRight: 16,
      '&:hover': {
        backgroundColor: '#eee'
      }
    },
    submitButton: {
      backgroundColor: '#EB5757',
      borderRadius: 999,
      padding: '8px 20px',
      color: 'var(--color-white)',
      '&:hover': {
        backgroundColor: '#ce4040'
      }
    },
    progress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translateY(-50%) translateX(-50%)'
    }
  })
);

interface Props {
  isOpen: boolean;
  setIsOpen: (e: boolean) => void;
  all_space_id: string;
  contract_id: string;
  has_electric_contract: boolean;
  detectError: () => void;
  isOverContractDate: boolean;
}

const OperationCancelModal: React.FC<Props> = props => {
  const { all_space_id, contract_id, has_electric_contract } = props;
  const styles = useStyles({});
  const history = useHistory();
  const [progress, setProgress] = React.useState<boolean>(false);

  const handleCancel = React.useCallback(async () => {
    setProgress(true);
    const method = 'POST';
    const bitlockManageEndpoint = `${ifBasePath}application/cancel-application`;
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-api-key': Redux.getStore().user.accessToken
    };

    const manageInfo = await getManagementCompany(
      all_space_id,
      Redux.getStore().user.accessToken
    );

    const body = JSON.stringify({
      [BasicItemKeys.ProjectId]: defaultProjectId,
      [BasicItemKeys.ApplicationCategory]:
        ApplicationCategory.CancelApplication,
      [BasicItemKeys.AllSpaceId]: all_space_id,
      [BasicItemKeys.PropertyId]: all_space_id,
      [BasicItemKeys.ContractId]: contract_id,
      [BasicItemKeys.RefreshToken]: Redux.getStore().user.refreshToken,
      [BasicItemKeys.ManagementCompanyName]:
        manageInfo.data && manageInfo.data.managementCompanyName
          ? manageInfo.data.managementCompanyName
          : '',
      [BasicItemKeys.ManagementCompanyAddress]:
        manageInfo.data && manageInfo.data.managementCompanyAddress
          ? manageInfo.data.managementCompanyAddress
          : '',
      [BasicItemKeys.ManagementCompanyPhoneNumber]:
        manageInfo.data && manageInfo.data.managementCompanyPhoneNumber
          ? manageInfo.data.managementCompanyPhoneNumber
          : '',
      [BasicItemKeys.NotificationEmail]:
        manageInfo.data && manageInfo.data.notificationEmail
          ? manageInfo.data.notificationEmail
          : ''
    });

    await fetch(bitlockManageEndpoint, {
      method,
      headers,
      body,
      mode: 'cors'
    })
      .then(res => {
        if (!res.ok) {
          throw Error(`${res.status}`);
        }
        history.push('/processing');
      })
      .catch(error => {
        if (error.status !== 409) {
          console.error(error);
        }
        console.error(error);
        props.detectError();
        setProgress(false);
        props.setIsOpen(false);
      });
  }, [all_space_id, contract_id, props, history]);

  return (
    <>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={props.isOpen && !props.isOverContractDate}
        className={styles.modal}
        onClose={() => props.setIsOpen(false)}
      >
        <div className={styles.container}>
          <IconButton
            className={styles.closeButton}
            onClick={() => props.setIsOpen(false)}
            disabled={progress}
          >
            <Close />
          </IconButton>
          <h2 className={styles.heading}>
            申し込みのキャンセル処理を行っても
            <br />
            よろしいですか？
          </h2>
          <p className={styles.desc}>
            申し込み内容は削除されます。
            <br />
            この操作は取り消すことが出来ません。
          </p>
          <div className={styles.buttonsContainer}>
            <Button
              className={styles.cancelButton}
              onClick={() => props.setIsOpen(false)}
              disabled={progress}
            >
              閉じる
            </Button>
            <Button
              className={styles.submitButton}
              onClick={handleCancel}
              disabled={progress}
            >
              処理を実行
            </Button>
          </div>
          {progress && (
            <div className={styles.progress}>
              <CircularProgress />
            </div>
          )}
        </div>
      </Modal>
      {/* 契約キャンセルアラート */}
      <MessageModal
        isOpen={props.isOpen && props.isOverContractDate}
        setIsOpen={props.setIsOpen as any}
        title={
          has_electric_contract
            ? '電気利用開始日を過ぎています。'
            : 'サービス利用開始日を過ぎています。'
        }
        message={['申し込みのキャンセル処理を行ってもよろしいですか？']}
        execute={handleCancel as any}
      />
    </>
  );
};

export default OperationCancelModal;
