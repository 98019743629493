import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import IconButton from '../atoms/IconButtonWithAuth';

const useStyles = makeStyles(() =>
  createStyles({
    // paging: {
    //   textAlign: "left",
    //   display: "inline-block",
    //   width: "100%",
    //   paddingLeft: "1em",
    //   color: "#2f80ed",
    //   margin: "32px auto 0",
    //   marginBottom: "7px"
    // },
    root: {
      display: 'flex',
      alignItems: 'center',

      fontSize: '14px',
      margin: '32px auto 0',
      background: 'var(--color-white)',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
      borderRadius: '16px 16px 0 0',
      width: 1216,
      // border: '1px solid #E0E0E0',
      // borderWidth: '0px 0px 1px',
      // borderCollapse: 'collapse'
      paddingTop: '25px'
    },
    counts: {
      marginLeft: '1em',
      marginRight: '1em'
    },
    flex: {
      display: 'flex',
      alignItems: 'baseline'
    },
    numText: {
      fontSize: '17px',
      color: 'var(--color-gray-3)',
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      marginLeft: '.5em',
      marginRight: '.5em'
    }
  })
);

interface P {
  onChangePage: (pageIndex: number) => void; // ページ変更時に上位レイヤーのトリガー発火のため
  rowsPerPage: number; // 1ページ単位に表示する行数(件数)
  page: number; // 今表示しているページ番号
  count: number; // 全体件数
  hideAllCount?: boolean; // 全体件数を非表示にする（※ API都合で取得できない場合用）
}

const TablePagination: React.FC<P> = (props: P) => {
  const { count, page, onChangePage, rowsPerPage, hideAllCount } = props;

  const styles = useStyles({});

  /**
   * 表示対象の最小のIndex
   */
  const displayFirst = page * rowsPerPage + 1;
  const pageEnd = (page + 1) * rowsPerPage;
  /**
   * 表示対象の最後のIndex
   */
  const displayEnd = count < pageEnd ? count : pageEnd;

  const goPreviousPage = React.useCallback(() => onChangePage(page - 1), [
    onChangePage,
    page
  ]);

  const goNextPage = React.useCallback(() => onChangePage(page + 1), [
    onChangePage,
    page
  ]);

  return (
    <div className={styles.root}>
      <Typography className={styles.counts}>
        {/* 0件時は「0件」以外は表示しない */}
        {count !== 0 && (
          <>
            {!hideAllCount && (
              <>
                <span className={styles.numText}>{count}</span>件中
              </>
            )}
            <span className={styles.numText}>{displayFirst}</span>-
          </>
        )}
        <span className={styles.numText}>{displayEnd}</span>件
      </Typography>
      <IconButton disabled={displayFirst === 1} onClick={goPreviousPage}>
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton disabled={displayEnd === count} onClick={goNextPage}>
        <KeyboardArrowRight />
      </IconButton>
    </div>
  );
};

export default TablePagination;
