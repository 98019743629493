import Redux from '../redux/ReduxConnector';

export const hasCorrectDataAuth = (): boolean => {
  const storeUser = Redux.getStore().user;
  if (
    storeUser.authType === 'OG_OPERATOR' ||
    storeUser.authType === 'OG_ADMIN' ||
    storeUser.authType === 'OSS_ADMIN'
  ) {
    return true;
  }
  return false;
};
