/**
 * 都道府県を表す型。
 * 都道府県Enum型だが、中身の実体は2桁の都道府県コードを表すstring値
 */
export type Prefecture = PrefectureEnum;

export enum PrefectureEnum {
  UNSELECTED = '00', // 未選択
  HOKKAIDO = '01',
  AOMORI = '02',
  IWATE = '03',
  MIYAGI = '04',
  AKITA = '05',
  YAMAGATA = '06',
  FUKUSHIMA = '07',
  IBARAKI = '08',
  TOCHIGI = '09',
  GUNMA = '10',
  SAITAMA = '11',
  CHIBA = '12',
  TOKYO = '13',
  KANAGAWA = '14',
  NIIGATA = '15',
  TOYAMA = '16',
  ISHIKAWA = '17',
  FUKUI = '18',
  YAMANASHI = '19',
  NAGANO = '20',
  GIFU = '21',
  SHIZUOKA = '22',
  AICHI = '23',
  MIE = '24',
  SHIGA = '25',
  KYOTO = '26',
  OSAKA = '27',
  HYOGO = '28',
  NARA = '29',
  WAKAYAMA = '30',
  TOTTORI = '31',
  SHIMANE = '32',
  OKAYAMA = '33',
  HIROSHIMA = '34',
  YAMAGUCHI = '35',
  TOKUSHIMA = '36',
  KAGAWA = '37',
  EHIME = '38',
  KOCHI = '39',
  FUKUOKA = '40',
  SAGA = '41',
  NAGASAKI = '42',
  KUMAMOTO = '43',
  OITA = '44',
  MIYAZAKI = '45',
  KAGOSHIMA = '46',
  OKINAWA = '47'
}

export const PrefectureJPMap: { [K in PrefectureEnum]: string } = {
  '00': '未選択',
  '01': '北海道',
  '02': '青森県',
  '03': '岩手県',
  '04': '宮城県',
  '05': '秋田県',
  '06': '山形県',
  '07': '福島県',
  '08': '茨城県',
  '09': '栃木県',
  '10': '群馬県',
  '11': '埼玉県',
  '12': '千葉県',
  '13': '東京都',
  '14': '神奈川県',
  '15': '新潟県',
  '16': '富山県',
  '17': '石川県',
  '18': '福井県',
  '19': '山梨県',
  '20': '長野県',
  '21': '岐阜県',
  '22': '静岡県',
  '23': '愛知県',
  '24': '三重県',
  '25': '滋賀県',
  '26': '京都府',
  '27': '大阪府',
  '28': '兵庫県',
  '29': '奈良県',
  '30': '和歌山県',
  '31': '鳥取県',
  '32': '島根県',
  '33': '岡山県',
  '34': '広島県',
  '35': '山口県',
  '36': '徳島県',
  '37': '香川県',
  '38': '愛媛県',
  '39': '高知県',
  '40': '福岡県',
  '41': '佐賀県',
  '42': '長崎県',
  '43': '熊本県',
  '44': '大分県',
  '45': '宮崎県',
  '46': '鹿児島県',
  '47': '沖縄県'
};

export const PrefectureUSMap: { [K in PrefectureEnum]: string } = {
  '00': 'Unselected',
  '01': 'Hokkaido',
  '02': 'Aomori',
  '03': 'Iwate',
  '04': 'Miyagi',
  '05': 'Akita',
  '06': 'Yamagata',
  '07': 'Fukushima',
  '08': 'Ibaraki',
  '09': 'Tochigi',
  '10': 'Gunma',
  '11': 'Saitama',
  '12': 'Chiba',
  '13': 'Tokyo',
  '14': 'Kanagawa',
  '15': 'Niigata',
  '16': 'Toyama',
  '17': 'Ishikawa',
  '18': 'Fukui',
  '19': 'Yamanashi',
  '20': 'Nagano',
  '21': 'Gifu',
  '22': 'Shizuoka',
  '23': 'Aichi',
  '24': 'Mie',
  '25': 'Shiga',
  '26': 'Kyoto',
  '27': 'Osaka',
  '28': 'Hyogo',
  '29': 'Nara',
  '30': 'Wakayama',
  '31': 'Tottori',
  '32': 'Shimane',
  '33': 'Okayama',
  '34': 'Hiroshima',
  '35': 'Yamaguchi',
  '36': 'Tokushima',
  '37': 'Kagawa',
  '38': 'Ehime',
  '39': 'Kouchi',
  '40': 'Fukuoka',
  '41': 'Saga',
  '42': 'Nagasaki',
  '43': 'Kumamoto',
  '44': 'Oita',
  '45': 'Miyazaki',
  '46': 'Kagoshima',
  '47': 'Okinawa'
};
