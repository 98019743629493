import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { getAggregationByAggregateDate } from '../../services/aggregation/get-aggregation-by-aggregate-date';

const AggregationTemplate: React.FC = () => {
  const [aggregations, setAggregations] = React.useState<Array<any>>([]);
  const [progress, setProgress] = React.useState(false);

  React.useEffect(() => {
    setProgress(true);
    getAggregationByAggregateDate('2020-01-30').then(data => {
      setAggregations(data);
      console.log(data);
    });
  }, []);

  return (
    <>
      <CircularProgress />
      {progress && (
        <div>
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default AggregationTemplate;
